export interface User {
  id: string;
}

export enum UserRoles {
  admin = 'ROLE_ADMIN',
  employee = 'ROLE_EMPLOYEE',
  manager = 'ROLE_MANAGER',
  hr = 'ROLE_HR',
}
