import React, {FC, memo} from 'react';
import {PageLoader} from '../PageLoader/PageLoader';
import clsx from 'clsx';

interface ISplashScreenProps {
  isFullScreen?: boolean;
}

const SplashScreen: FC<ISplashScreenProps> = ({isFullScreen = true}) => (
  <div
    className={clsx('splash-screen', {
      'full-screen': isFullScreen,
    })}>
    <PageLoader />
  </div>
);

export default memo(SplashScreen);
