import React, {FC, memo} from 'react';
import {Button, TextField} from '@material-ui/core';
import {DropdownMenu} from '../../../components/DropdownMenu/DropdownMenu';
import {IEmployeePagination, UpdatePagination} from '../type';
import {MODALS, useModals} from '../../../containers';
import {TextInput} from '../../../shared/TextInput';
import {PaginationWithTotal} from 'http-client/types';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';

interface IEmployeesToolbarProps {
  pagination: PaginationWithTotal;
  value?: string;
  setValue: any;
}

const EmployeesToolbar: FC<IEmployeesToolbarProps> = ({
  pagination,
  value,
  setValue,
}) => {
  const {openModal} = useModals();
  const history = useHistory();
  const handleOpenModal = () => {
    openModal(MODALS.Employee);
  };

  return (
    <div className="employee-header">
      <div className="custom-toolbar">
        <div className="toolbar-heading">
          <p>
            <i className="icon-nav-item-5" />
            Employees
          </p>
        </div>
        <div className="toolbar-actions">
          <div className="toolbar-actions__item">
            <TextInput
              placeholder="Search by employees"
              defaultValue={value}
              onChange={(event) => {
                history.replace(
                  `${history.location.pathname}?${queryString.stringify({
                    ...pagination,
                    search: event.target.value,
                  })}`,
                );
                setValue(event.target.value);
              }}
              InputProps={{
                startAdornment: <i className="icon-search" />,
              }}
            />
          </div>
          <div className="toolbar-actions__item">
            <DropdownMenu sm />
          </div>
          <div className="toolbar-actions__item">
            <Button
              variant="contained"
              color="primary"
              className="btn-low"
              startIcon={<i className="icon-plus" />}
              onClick={handleOpenModal}>
              Add Employee
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EmployeesToolbar);
