import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

type Action = (payload?: any) => {type: string; payload?: unknown};

const useDispatchOnMount = (action: Action, payload?: unknown): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action(payload));
  }, [dispatch]);

  return;
};

export default useDispatchOnMount;
