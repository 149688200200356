import * as yup from 'yup';
import i18n from '../../i18n/index';

export interface RequestTimeOffFormSchema {
  absenceTypeId: string;
  dateFrom: string | number;
  dateTo: string | number;
  approversIds: string[] | string;
  comment: string;
}

export const schema = yup.object().shape({
  absenceTypeId: yup
    .object()
    .nullable()
    .required(i18n.t('employeeSection:errors.theFieldRequired')),
  dateFrom: yup
    .date()
    .nullable()
    .required(i18n.t('employeeSection:errors.theFieldRequired')),
  dateTo: yup
    .date()
    .nullable()
    .required(i18n.t('employeeSection:errors.theFieldRequired')),
  approversIds: yup
    .array()
    .nullable()
    .min(1, i18n.t('employeeSection:errors.theFieldRequired'))
    .required(i18n.t('employeeSection:errors.theFieldRequired')),
  comment: yup
    .string()
    .nullable()
    .required(i18n.t('employeeSection:errors.theFieldRequired')),
});
