export enum PathNames {
  login = '/login',
  forgotPassword = '/forgot-password',
  forgotPasswordRedirect = '/forgot-password/redirect',
  forgotPasswordVerify = '/forgot-password/verify/:token',
  main = '/',
  employees = '/employees',
  employeesForHR = '/employees-hr',
  structure = '/structure',
  documents = '/documents',
  absences = '/absences',
  absencesForHR = '/absences-hr',
  settings = '/settings',
  notFound = '*',
  profile = '/profile',
  components = '/components',
}

export const UnauthPaths = new Set<string>([
  PathNames.login,
  PathNames.forgotPasswordRedirect,
  PathNames.forgotPassword,
  PathNames.forgotPasswordVerify,
]);
