import {useCallback, useState} from 'react';

interface IUseLazyQueryParams<RequestType> {
  request: (params: RequestType) => Promise<any>;
  params?: RequestType;
}

type IUseLazyQueryResult<ResponseType, RequestType> = [
  (params?: RequestType) => Promise<ResponseType | void>,
  {
    data: ResponseType | null;
    isError: boolean;
    isLoading: boolean;
    error: Record<string, unknown> | string | null;
  },
];

const useLazyQuery = <ResponseType, RequestType = undefined>(
  request: IUseLazyQueryParams<RequestType>['request'],
): IUseLazyQueryResult<ResponseType, RequestType> => {
  const [data, setData] = useState<ResponseType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Record<string, unknown> | string | null>(
    null,
  );

  const getData = useCallback(
    async (params = {} as RequestType): Promise<ResponseType | void> => {
      setIsLoading(true);
      try {
        const result = await request(params);

        setData(result);

        return result;
      } catch (error) {
        setError(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [request],
  );

  return [getData, {data, isError, isLoading, error}];
};

export default useLazyQuery;
