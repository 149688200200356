import React, {FC, useReducer, useEffect, useCallback, useMemo} from 'react';
import {AuthContext} from './AuthContext';
import {CredentialsManager} from 'cookies';
import {getInitialState, reducer} from './reducer';
import {AUTH_ACTION} from './actions';
import {useHistory} from 'react-router';
import {PathNames} from '../../routing/pathNames';

const AuthProvider: FC = ({children}) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const history = useHistory();

  const login = useCallback((authInfo) => {
    dispatch({type: AUTH_ACTION.login, payload: authInfo});
  }, []);

  const logout = useCallback(() => {
    dispatch({type: AUTH_ACTION.logout});
    history.push(PathNames.login);
  }, []);

  const authState = useMemo(() => ({...state, login, logout}), [state]);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
