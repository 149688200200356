import {AnyAction} from 'redux';

export enum PageActions {
  SET_TITLE = 'SET_TITLE',
}

export function setTitle(payload: string): AnyAction {
  return {
    type: PageActions.SET_TITLE,
    payload,
  };
}
