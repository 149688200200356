import {AnyAction} from 'redux';

export enum AuthActions {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',

  LOGOUT = 'LOGOUT',
}
export interface ActionLogin {
  readonly RequestData: any;
  readonly ResponseData: any;
  readonly ResponseError: string;
}

export function actionLogin(payload: ActionLogin['RequestData']): AnyAction {
  return {
    type: AuthActions.LOGIN,
    payload,
  };
}

export function actionLoginSuccess(
  payload: ActionLogin['ResponseData'],
): AnyAction {
  return {
    type: AuthActions.LOGIN_SUCCESS,
    payload,
  };
}

export function actionLoginError(
  payload: ActionLogin['ResponseError'],
): AnyAction {
  return {
    type: AuthActions.LOGIN_ERROR,
    payload,
  };
}

export function actionLogout(): AnyAction {
  return {
    type: AuthActions.LOGOUT,
  };
}
