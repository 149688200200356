import React, {FC} from 'react';
import {components} from 'react-select';

export const DropdownIndicator: FC<any> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="icon-arrow-drop-down"></i>
    </components.DropdownIndicator>
  );
};
