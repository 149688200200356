// const prefix = '/api';

export const API_ROUTES = {
  //#region Auth
  login: '/auth/login',
  getRole: '/roles/current',
  //#endregion

  //#region Forgot Password
  forgotPassword: '/forgot-password',
  forgotPasswordTokenVerify: '/forgot-password/token',
  forgotPasswordChange: '/forgot-password/change',
  //#endregion

  //#region Two-factor Auth
  sendCode: '/two-factor/send-code',
  checkCode: '/two-factor/check-code',
  //#endregion

  //#region Employee
  employees: '/employees',
  employeesList: '/employees/employee',
  upcomingEmployees: '/employees/soon',
  archivedEmployees: '/employees/archived',
  //#endregion

  //#region Profile
  profile: '/profile',
  profileLanguage: '/profile/language',
  //#endregion

  //#region Absence
  createAbsence: '/absence/request',
  getAbsences: '/profile',
  getCurrentAbsence: '/absence/current',
  getCloseVocation: '/absence/closest-vacations',
  //#endregion

  //#region AbsenceType
  getAbsenceType: '/absence-type',
  //#endregion

  //#region Birthdays
  getBirthdays: '/birthday',
  //#endregion

  //#region Departments
  departments: '/departments',
  //#endregion

  //#region Departments
  positions: '/positions',
  //#endregion

  //#region Departments
  roles: '/roles',
  //#endregion
};
