import React, {ComponentClass, FC, useContext, useState} from 'react';
import {MODALS_TYPE} from './availableModals';
import {BACKDROP_TIMEOUT} from 'modals';
import {ModalI, Modals, ModalsContext} from './ModalContext';
import {Employee} from '../../modals/Employee';

export const useModals = (): Modals => useContext(ModalsContext);

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  initialModals: MODALS_TYPE;
}

// only use for global modals that can be displayed on multiple pages
export const ModalsProvider: React.FC<ModalProps> = ({
  children,
  initialModals,
}: ModalProps) => {
  const [modalTypes, setModalTypes] = useState<MODALS_TYPE>(initialModals);
  const [modalsList, setModalsList] = useState<Array<ModalI>>([]);

  const addModal: Modals['addModal'] = (key, ModalComponent): void => {
    setModalTypes((state) => ({...state, [key]: ModalComponent}));
  };

  const openModal: Modals['openModal'] = (key, props = {}): void => {
    setModalsList((state) => [
      ...state,
      {
        key,
        open: true,
        Component: modalTypes[key],
        props,
      },
    ]);
  };

  const closeModal: Modals['closeModal'] = (key?: string): void => {
    if (key === undefined) {
      setModalsList([]);
    } else {
      setModalsList((state) => {
        const newState = [...state];
        const index = state.findIndex(({key: modalKey}) => key === modalKey);
        newState[index] = {...newState[index], open: false};
        return newState;
      });
      setTimeout(
        () =>
          setModalsList((state) => state.filter((item) => item.key !== key)),
        BACKDROP_TIMEOUT,
      );
    }
  };

  return (
    <ModalsContext.Provider value={{addModal, openModal, closeModal}}>
      {modalsList.map((item) => (
        <item.Component {...item.props} open={item.open} key={item.key} />
      ))}
      {children}
    </ModalsContext.Provider>
  );
};
