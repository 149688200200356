import React, {ComponentClass, FC} from 'react';
import {MODALS} from './availableModals';

export interface Modals {
  addModal(key: MODALS, modal: ComponentClass<any> | FC<any>): void;
  openModal(key: MODALS, props?: {[key: string]: any}): void;
  closeModal(key?: MODALS): void;
}

export interface ModalI {
  key: string;
  open: boolean;
  Component: ComponentClass<any> | FC<any>;
  props: {[key: string]: any};
}

const initialState: Modals = {
  closeModal: (): void => {
    throw new Error('Not implemented');
  },
  openModal: (): void => {
    throw new Error('Not implemented');
  },
  addModal: (): void => {
    throw new Error('Not implemented');
  },
};

export const ModalsContext = React.createContext(initialState);
