import * as yup from 'yup';
import {IValueLabelPair} from '../../interfaces/shared';
import {Employee} from '../../interfaces/employee';
import {SetFieldValue} from 'react-hook-form';

export interface IEmployeeForm {
  fullName: string;
  email: string;
  phone: string;
  emergencyContact: string;
  skype: string;
  department: IValueLabelPair<string>;
  position: IValueLabelPair<string>;
  roles: IValueLabelPair<string>[];
  birthday: Date;
  employmentStartDate: Date;
  notes?: string;
}

export const schema = yup.object().shape({
  email: yup.string().email().required('The field is required'),
  fullName: yup.string().required('The field is required'),
  phone: yup.string().required('The field is required'),
  emergencyContact: yup.string().required('The field is required'),
  skype: yup.string().required('The field is required'),
  department: yup
    .object<IValueLabelPair<string>>()
    .required('The field is required'),
  position: yup
    .object<IValueLabelPair<string>>()
    .required('The field is required'),
  roles: yup
    .array(yup.object<IValueLabelPair<string>>())
    .required('The field is required'),
  birthday: yup.date().required('The field is required'),
  employmentStartDate: yup.date().required('The field is required'),
  notes: yup.string(),
});

export const setFormValues = (
  employee: Employee,
  setValue: SetFieldValue<any>,
): any => {
  const {
    firstName,
    lastName,
    email,
    position,
    skype,
    roles,
    employmentStartDate,
    birthday,
    phone,
    department,
    emergencyContact,
    notes,
  } = employee;

  // 312312

  Object.entries({
    birthday: new Date(birthday * 1000),
    employmentStartDate: new Date(employmentStartDate * 1000),
    fullName: `${firstName} ${lastName}`,
    email,
    skype,
    phone,
    position,
    emergencyContact,
    department: {
      label: department.name,
      value: department.id,
    },
    notes,
  }).forEach(([key, value]) => setValue(key, value));
};
