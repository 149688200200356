import React, {FC, memo, useEffect, useMemo, useState} from 'react';
import {Column} from 'material-table';
import CustomTable from 'components/CustomMaterialTable/CustomMaterialTable';
import EmployeeColumn from './columns/EmployeeColumn';
import ContactsColumn from './columns/ContactsColumn';
import AbsenceColumn from './columns/AbsenceColumn';
import BalanceColumn from './columns/BalanceColumn';
import ActionsColumn from './columns/ActionsColumn';
import BirthdayColumn from './columns/BirthdayColumn';

import CustomTablePagination from '../../../../components/CustomMaterialTable/CustomTablePagination';
import {Employee} from '../../../../interfaces/employee';
import {IEmployeePagination, UpdateData, UpdatePagination} from '../../type';
import {PaginationWithTotal} from 'http-client/types';
import classNames from 'classnames';
import usePaginatedEmployees from '../../../../hooks/usePaginatedEmployees';
import {EmployeesDataRequestFn} from '../../../../constants';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';

interface IEmployeesTableProps {
  activeTab: number;
  searchValue?: string;
  noBoxShadow?: boolean;
}

const EmployeesTable: FC<IEmployeesTableProps> = ({
  activeTab,
  searchValue,
  noBoxShadow,
}): JSX.Element => {
  const {
    pagination,
    list,
    updateData,
    updatePagination,
    isLoading,
  } = usePaginatedEmployees(EmployeesDataRequestFn[activeTab]);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {
    isMounted && updatePagination('search', searchValue);
  }, [searchValue]);

  const tableColumns: Column<Employee>[] = useMemo(
    () => [
      {
        title: 'Employee',
        render: (employee) => <EmployeeColumn employee={employee} />,
      },
      {
        title: 'Birthday',
        render: (employee) => <BirthdayColumn employee={employee} />,
      },
      {
        title: 'Contacts',
        render: (employee) => <ContactsColumn employee={employee} />,
      },
      {
        title: 'Skype',
        field: 'skype',
      },
      {
        title: 'Current absence',
        render: (employee) => <AbsenceColumn employee={employee} />,
      },
      {
        title: 'Balance',
        render: (employee) => <BalanceColumn employee={employee} />,
      },
      {
        title: 'Experience',
        field: 'position',
      },
      {
        title: '',
        render: (employee) => (
          <ActionsColumn updateData={updateData} employee={employee} />
        ),
      },
    ],
    [list],
  );

  const components = useMemo(
    () => ({
      Toolbar: () => null,
      Pagination: () => (
        <CustomTablePagination
          pagination={pagination}
          onPrePageChange={(newPerPage) =>
            updatePagination('perPage', newPerPage)
          }
          paginationChange={(_, newPage) => updatePagination('page', newPage)}
        />
      ),
    }),
    [updatePagination, pagination],
  );

  const options = useMemo(
    () => ({
      pageSize: pagination.perPage,
    }),
    [pagination.perPage, list],
  );

  return (
    <div className={classNames({'no-box-shadow': noBoxShadow})}>
      <CustomTable
        isLoading={isLoading}
        columns={tableColumns}
        components={components}
        options={options}
        data={list}
      />
    </div>
  );
};

export default memo(EmployeesTable);
