import client, {API_ROUTES} from '../../http-client';
import {
  APIAbsenceType,
  APIAbsenceTypeDeleteI,
  APIAbsenceTypePostI,
  APIAbsenceTypePutI,
} from './types';
import {APIRequestHandler, APIRequestHandlerGet} from '../../http-client/types';
import axios, {Canceler} from 'axios';

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const getAbsenceTypes: APIRequestHandlerGet<APIAbsenceType> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.getAbsenceType, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const createAbsenceType: APIRequestHandler<APIAbsenceTypePostI> = async (
  params,
) => {
  return await client.post(API_ROUTES.departments, params);
};

export const editAbsenceType: APIRequestHandler<APIAbsenceTypePutI> = async (
  params,
) => {
  const {id, ...rest} = params;
  return await client.put(`${API_ROUTES.departments}/${id}`, rest);
};

export const deleteAbsenceType: APIRequestHandlerGet<APIAbsenceTypeDeleteI> = async (
  params,
) => {
  if (!params) {
    return;
  }

  return await client.delete(`${API_ROUTES.departments}/${params.id}`);
};
