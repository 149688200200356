import React from 'react';
import BadgeApprove from '../../../../../components/Badge/BadgeApprove';
import {Avatar, Badge} from '@material-ui/core';

const ApprovesColumn = () => {
  return (
    <div className="approves">
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
        <div className="approver-details">
          <div className="employee-info-card employee-column">
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <span className="badge-status success">
                  <i className="icon-done" />
                </span>
              }>
              <Avatar className="avatar-border border-success" alt="" src="" />
            </Badge>
            <div className="employee-info-card__data">
              <p className="employee-info-card__data--name subtext">
                Brooklyn Simmons
              </p>
              <p className="employee-info-card__data--role caption">
                Digital Marketing Manager
              </p>
            </div>
          </div>
          <p className="text-medium">Rejected date: 11.06.2021</p>
          <p className="comment-title">Comment</p>
          <p className="comment-text">
            К сожалению я не могу одобрить твой запрос, потому, что в этот
            период на проекте будет завал и ты нам очень нужен. Давай завтра
            обсудим как можно решить твою просьбу.
          </p>
        </div>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
      <div className="approval-status__item">
        {/*<BadgeApprove approve={approve} />*/}
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <span className="badge-status success">
              <i className="icon-done" />
            </span>
          }>
          <Avatar className="avatar-border border-success" alt="" src="" />
        </Badge>
      </div>
    </div>
  );
};

export default ApprovesColumn;
