import React, {FC, memo} from 'react';
import useSetTitle from '../../hooks/useSetTitle';

interface IPageToolbarProps {
  title: string;
  icon?: string;
  children?: React.ReactNode;
}

const PageToolbar: FC<IPageToolbarProps> = ({
  title,
  icon = 'icon-nav-item-5',
  children,
}) => {
  useSetTitle(title);

  return (
    <div className="custom-table-holder">
      <div className="custom-toolbar">
        <div className="toolbar-heading">
          <p>
            <i className={icon} />
            {title}
          </p>
        </div>
        {children && <div className="toolbar-actions">{children}</div>}
      </div>
    </div>
  );
};

export default memo(PageToolbar);
