import React, {FC} from 'react';
import {components, SingleValueProps} from 'react-select';

export const SingleValue: FC<SingleValueProps<any>> = ({
  children,
  ...props
}) => {
  let classIcon = '';
  if (typeof children === 'string') {
    classIcon = `icon-${children.toString().replace(' ', '-').toLowerCase()} ${
      children.toString().replace(' ', '-').toLowerCase() === 'day-off'
        ? 'icon-yellow'
        : ''
    } ${
      children.toString().replace(' ', '-').toLowerCase() === 'vacation'
        ? 'icon-green'
        : ''
    } ${
      children.toString().replace(' ', '-').toLowerCase() === 'sick-leave'
        ? 'icon-red'
        : ''
    }`;
  }
  return (
    <components.SingleValue {...props}>
      <i className={classIcon}></i> {children}
    </components.SingleValue>
  );
};
