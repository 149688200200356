import {useCallback, useState} from 'react';
import {debounce, DebouncedFunc} from 'lodash-es';

interface IUseDebounceProps {
  callback: () => void | Promise<void>;
  wait?: number;
  hasDebounceOnMount?: boolean;
}

const useDebounce = (
  callback: IUseDebounceProps['callback'],
  wait: IUseDebounceProps['wait'] = 500,
  hasDebounceOnMount: IUseDebounceProps['hasDebounceOnMount'] = false,
): DebouncedFunc<() => void | Promise<void>> => {
  return useCallback(debounce(callback, wait), [callback, wait]);
};

export default useDebounce;
