import React, {FC, memo} from 'react';

interface IModalHeaderProps {
  title: string;
}

const ModalHeader: FC<IModalHeaderProps> = ({title}) => {
  return (
    <div className="modal-header">
      <h3 className="modal-header__title">{title}</h3>
    </div>
  );
};

export default memo(ModalHeader);
