import React, {FC, useCallback, useState} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {
  Button,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {createAbsenceType} from 'services/absence-type';
import {APIError, StatusCodes} from 'http-client/types';
import {AbsenceTypeI} from 'interfaces/absence';
import {useTranslation} from 'react-i18next';

type CreateAbsenceProps = {
  open: boolean;
  updateData: () => void;
};

export const CreateAbsence: FC<CreateAbsenceProps> = ({
  open,
  updateData,
}): JSX.Element => {
  const {closeModal} = useModals();
  const {t} = useTranslation(['settings', 'general']);

  const onClose = useCallback(() => closeModal(MODALS.CreateAbsence), [
    closeModal,
  ]);

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [period, setPeriod] = useState<string>('durable');

  const handleChangePeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeriod(event.target.value);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      await createAbsenceType({
        name,
        period: period === 'durable',
      });

      onClose();
      updateData();
    } catch (_error: unknown) {
      const error = _error as APIError<AbsenceTypeI>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setError(error.response.data.error);
        return;
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          {t('absencesTab:createAbsence')}
        </h3>
      </div>
      <div className="modal-content">
        <div className="form-control input-row">
          <InputLabel htmlFor="absence">{t('absencesTab:absence')}</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            name="name"
            value={name}
            onChange={handleChangeName}
          />
        </div>
        <div className="form-control input-row">
          <RadioGroup
            aria-label="period"
            name="period"
            value={period}
            onChange={handleChangePeriod}>
            <FormControlLabel
              value="durable"
              control={<Radio color="primary" />}
              label={t('absenceType:period.durable')}
            />
            <FormControlLabel
              value="oneDay"
              control={<Radio color="primary" />}
              label={t('absenceType:period.oneDay')}
            />
          </RadioGroup>
        </div>
      </div>
      <div className="modal-footer">
        <Button disableRipple onClick={onClose}>
          {t('terms:cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={handleSubmit}>
          {t('absencesTab:createAbsence')}
        </Button>
      </div>
    </Modal>
  );
};
