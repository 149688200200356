import React, {FC, lazy} from 'react';

import {Redirect, Switch} from 'react-router-dom';
import {PathNames} from '../../routing/pathNames';
import {RouteResolver} from 'routing/RouteResolver';

const Step1 = lazy(() => import('./Step1'));
const Step2 = lazy(() => import('./Step2'));
const Step3 = lazy(() => import('./Step3'));

const STEPS = [
  {
    Component: Step1,
    path: PathNames.forgotPassword,
    exact: true,
  },
  {
    Component: Step2,
    path: PathNames.forgotPasswordRedirect,
    exact: true,
  },
  {
    Component: Step3,
    path: PathNames.forgotPasswordVerify,
    exact: true,
  },
];

const ForgotPassword: FC = () => {
  return (
    <div id="wrapper" className="page-wrap">
      <div className="full-height-holder">
        <div className="full-height-inner">
          <Switch>
            {STEPS.map(({...rest}, i) => (
              <RouteResolver key={i} {...rest} />
            ))}
            <Redirect to={PathNames.forgotPassword} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
