import React, {FC} from 'react';
import DDILogo from '../../images/DDI.svg';
import classNames from 'classnames';

type PageLoaderProps = {
  withContainer?: boolean;
  withOpacity?: boolean;
  withAbsolute?: boolean;
};

const loaderGif = (
  <div className="loader-gif">
    <img className="only-logo" width="56" height="56" src={DDILogo} alt="DDI" />
    <span className="page-loader-bar" />
  </div>
);

export const PageLoader: FC<PageLoaderProps> = ({
  withContainer,
  withOpacity,
  withAbsolute,
}) => {
  const pageLoaderClass = classNames({
    'page-loader': true,
    'page-loader-opacity': withOpacity,
    'page-loader-absolute': withAbsolute,
  });
  return (
    <>
      {withContainer ? (
        <div className={pageLoaderClass}>
          <div className="page-loader-container">{loaderGif}</div>{' '}
        </div>
      ) : (
        loaderGif
      )}
    </>
  );
};
