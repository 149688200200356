import {API_ROUTES} from '../../http-client';
import client from '../../http-client/client';
import {APIRequestHandler, APIRequestHandlerGet} from '../../http-client/types';
import axios, {Canceler} from 'axios';
import {APIEmployee, APIEmployeeCreate, APIEmployeeDelete} from './types';

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;
export const getEmployees: APIRequestHandlerGet<APIEmployee> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.employees, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const deleteEmployee: APIRequestHandlerGet<APIEmployeeDelete> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.delete(`${API_ROUTES.employees}/${params?.id}`);
};

export const createEmployee: APIRequestHandler<APIEmployeeCreate> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(API_ROUTES.employees, params);

  return response.data;
};

export const getEmployeesList: APIRequestHandlerGet<APIEmployee> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.employeesList, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const getUpcomingEmployees: APIRequestHandlerGet<APIEmployee> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.upcomingEmployees, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const getArchivedEmployees: APIRequestHandlerGet<APIEmployee> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.archivedEmployees, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};
