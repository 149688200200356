import {ForgotPassword} from 'modals';
import {ComponentClass, FC} from 'react';
import {RequestTimeOff} from '../../modals/RequestTimeOff';
import {CurrentAbsences} from '../../modals/CurrentAbsences';
import {ClosestVacations} from '../../modals/ClosestVacations';
import {Birthday} from '../../modals/Birthday';
import {Employee} from '../../modals/Employee';
import {RequestApprove} from '../../modals/RequestApprove';
import {RequestReject} from '../../modals/RequestReject';
import {RequestChangeStatus} from '../../modals/RequestChangeStatus';
import {CreateDepartment} from '../../modals/CreateDepartment';
import {CreatePosition} from '../../modals/CreatePosition';
import {CreateAbsence} from '../../modals/CreateAbsence';
import {CreateCoefficient} from '../../modals/CreateCoefficient';
import {EditDepartment} from '../../modals/EditDepartment';

// only use for global modals that can be displayed on multiple pages
export enum MODALS {
  ForgotPassword = 'forgotPassword',
  RequestTimeOff = 'requestTimeOff',
  CurrentAbsences = 'currentAbsences',
  ClosestVacations = 'closestVacations',
  Birthday = 'birthday',
  Employee = 'employee',
  RequestApprove = 'requestApprove',
  RequestReject = 'requestReject',
  RequestChangeStatus = 'requestChangeStatus',
  CreateDepartment = 'createDepartment',
  CreatePosition = 'createPosition',
  CreateAbsence = 'createAbsence',
  CreateCoefficient = 'createCoefficient',
  EditDepartment = 'editDepartment',
}

export type MODALS_TYPE = {
  [key in MODALS]: ComponentClass<any> | FC<any>;
};

export const AVAILABLE_MODALS: MODALS_TYPE = {
  [MODALS.ForgotPassword]: ForgotPassword,
  [MODALS.RequestTimeOff]: RequestTimeOff,
  [MODALS.CurrentAbsences]: CurrentAbsences,
  [MODALS.ClosestVacations]: ClosestVacations,
  [MODALS.Birthday]: Birthday,
  [MODALS.Employee]: Employee,
  [MODALS.RequestApprove]: RequestApprove,
  [MODALS.RequestReject]: RequestReject,
  [MODALS.RequestChangeStatus]: RequestChangeStatus,
  [MODALS.CreateDepartment]: CreateDepartment,
  [MODALS.CreatePosition]: CreatePosition,
  [MODALS.CreateAbsence]: CreateAbsence,
  [MODALS.CreateCoefficient]: CreateCoefficient,
  [MODALS.EditDepartment]: EditDepartment,
};
