import React, {memo} from 'react';
import {ISelectProps, Select} from '../Select';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
} from 'react-hook-form';
import {IValueLabelPair} from '../../interfaces/shared';

type IFormSelectProps = ISelectProps & {
  name: string;
  control: Control;
  errors?: FieldErrors;
};

export const FormSelect = memo(
  ({name, control, errors, ...restProps}: IFormSelectProps) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({value, onChange}: ControllerRenderProps) => (
          <Select
            error={errors ? errors?.[name]?.message : ''}
            className={
              errors && !!errors?.[name]?.message ? 'select-errors' : ''
            }
            onChange={onChange}
            value={value}
            {...restProps}
          />
        )}
      />
    );
  },
);
