import {
  getEmployeesList,
  getArchivedEmployees,
  getUpcomingEmployees,
} from '../services/employee';
import {usePaginatedEmployeesQueryProps} from '../hooks/usePaginatedEmployees';
import React from 'react';

export const EmployeesDataRequestFn: {
  [key: string]: usePaginatedEmployeesQueryProps;
} = {
  0: getEmployeesList,
  1: getUpcomingEmployees,
  2: getArchivedEmployees,
};

export const BalanceType = {
  'sick-leave': {icon: 'icon-sick-leave-dot', title: 'sick leave'},
  'day-off': {icon: 'icon-day-off-dot', title: 'day off'},
  vacation: {icon: 'icon-vacation-dot', title: 'vacation'},
};
