import React, {Suspense} from 'react';
import {Switch} from 'react-router-dom';
import {RouteResolver} from './RouteResolver';
import {Pages} from './pages';
import {AVAILABLE_MODALS, ModalsProvider} from '../containers';
import {UserProfileProvider} from '../containers';
import SplashScreen from 'components/SplashScreen/SplashScreen';

const Routes = (): JSX.Element => {
  return (
    <Suspense fallback={<SplashScreen />}>
      <ModalsProvider initialModals={AVAILABLE_MODALS}>
        <UserProfileProvider>
          <Switch>
            {Pages.map(({...rest}, i) => (
              <RouteResolver key={i} {...rest} />
            ))}
          </Switch>
        </UserProfileProvider>
      </ModalsProvider>
    </Suspense>
  );
};

export default Routes;
