import React, {forwardRef} from 'react';
import {ITextInputProps, TextInput} from 'shared/TextInput';
import {FieldErrors} from 'react-hook-form';

type IFormTextInputProps = ITextInputProps & {
  name: string;
  errors?: FieldErrors;
};

const FormTextInput = forwardRef(
  ({name, errors, ...restProps}: IFormTextInputProps, ref) => {
    return (
      <TextInput
        fullWidth
        name={name}
        helperText={errors ? errors[name]?.message : ''}
        error={errors ? !!errors[name]?.message : false}
        ref={ref}
        {...restProps}
      />
    );
  },
);

export default FormTextInput;
