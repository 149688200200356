import {AxiosError} from 'axios';
import {AnyAction} from 'redux';
import {AuthActions} from './actions';

export interface AuthReducerState {
  user?: any;
  signOutFn?: (to: any) => void;
  isAuthorized?: boolean;
  isLoading?: boolean;

  error?: string | AxiosError | Error; //@todo better, to have a string or custom error object
}
const INITIAL_STATE = {
  user: undefined,
  isAuthorized: false,
  signOutFn: undefined,
  isLoading: false,
  error: undefined,
};

export const authReducer = (
  state: AuthReducerState = INITIAL_STATE,
  action: AnyAction,
): AuthReducerState => {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        signOutFn: action.payload.signOutFn,
        isLoading: true,
      };
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthorized: true,
        isLoading: false,
        error: undefined,
      };
    case AuthActions.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case AuthActions.LOGOUT:
    default:
      return state;
  }
};
