import React, {FC, useEffect, useState} from 'react';
import {FormControl, MenuItem, Select} from '@material-ui/core';
import {ArrowSelect} from './ArrowSelect';

interface PerPageProps {
  perPage: number;
  onPrePageChange?: (perPage: number) => void;
}

export const PerPage: FC<PerPageProps> = ({perPage, onPrePageChange}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    if (onPrePageChange) {
      onPrePageChange(event.target.value as number);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="per-page">
      Rows per page{' '}
      <FormControl>
        <Select
          variant="outlined"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={perPage}
          onChange={handleChange}
          IconComponent={ArrowSelect}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
