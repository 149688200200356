import {CustomPagination} from 'components/Pagination/CustomPagination';
import {PaginationWithTotal} from 'http-client/types';
import React, {FC, SyntheticEvent} from 'react';

interface ICustomTablePaginationProps {
  pagination: PaginationWithTotal;
  onPrePageChange: (perPage: number) => void;
  paginationChange: (event: SyntheticEvent<EventTarget>, page: number) => void;
}

const CustomTablePagination: FC<ICustomTablePaginationProps> = ({
  pagination,
  onPrePageChange,
  paginationChange,
}: ICustomTablePaginationProps) => {
  const {perPage, page, total, totalPages} = pagination;

  return (
    <div className="material-table-pagination">
      <CustomPagination
        totalPages={totalPages}
        perPage={perPage}
        total={total}
        currentPage={page}
        paginationChange={paginationChange}
        onPrePageChange={onPrePageChange}
      />
    </div>
  );
};

export default CustomTablePagination;
