import React, {FC, memo} from 'react';
import {Avatar} from '@material-ui/core';
import {IEmployeeColumnProps} from '../../../type';

const EmployeeColumn: FC<IEmployeeColumnProps> = ({employee}): JSX.Element => {
  return (
    <div className="employee-personal-data">
      <Avatar className="avatar-md" src={employee.image}>
        JD
      </Avatar>
      <div className="employee-data">
        <p className="employee-data__name text-overflow">{`${employee.firstName} ${employee.lastName}`}</p>
        <p className="caption text-overflow">{employee.position.name}</p>
      </div>
    </div>
  );
};

export default memo(EmployeeColumn);
