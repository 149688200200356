import React, {FC} from 'react';
import cx from 'classnames';
import {Modal as MaterialModal, Backdrop, Fade} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  lg?: boolean;
  sm?: boolean;
  xl?: boolean;
  xxl?: boolean;
  withTabs?: boolean;
  centerContent?: boolean;
  withCloseButton?: boolean;
  contentScrolled?: boolean;
  fullHeight?: boolean;
  maxWidth?: number;
};

export const BACKDROP_TIMEOUT = 500;

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  lg = false,
  sm,
  xl,
  xxl,
  children,
  withTabs = false,
  centerContent,
  withCloseButton = true,
  contentScrolled,
  fullHeight = true,
  maxWidth = 500,
}) => (
  <MaterialModal
    className={cx('modal-wrapper', {
      'tabs-modal': withTabs,
      'modal-lg': lg,
      'modal-sm': sm,
      'modal-xl': xl,
      'modal-xxl': xxl,
      'content-scrolled': contentScrolled,
      'modal-full-height': fullHeight,
    })}
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: BACKDROP_TIMEOUT,
    }}>
    <Fade in={open}>
      <div
        className={cx('modal-body', {
          'text-center': centerContent,
          'max-width': maxWidth,
        })}>
        {withCloseButton && (
          <div className="close-modal text-right" onClick={onClose}>
            <CloseIcon />
          </div>
        )}
        {children}
      </div>
    </Fade>
  </MaterialModal>
);
