import React, {FC, useMemo} from 'react';

interface IStatusColumnProps {
  type?: string;
  approveLabel?: boolean;
}

const StatusColumn: FC<IStatusColumnProps> = ({
  type = 'vacation',
  approveLabel,
}) => {
  const statusType = useMemo(() => {
    switch (type) {
      case 'vacation':
        return (
          <p className="text-with-icon">
            <i className="icon-vacation icon-green" />
            <span className="text-semi-bold">Vacation</span>
          </p>
        );
      case 'dayOff':
        return (
          <p className="text-with-icon">
            <i className="icon-day-off icon-green" />
            <span className="text-semi-bold">Day off</span>
          </p>
        );
      case 'sickLeave':
        return (
          <p className="text-with-icon">
            <i className="icon-sick-leave icon-green" />
            <span className="text-semi-bold">Sick leave</span>
          </p>
        );
      default:
        return null;
    }
  }, [type]);

  return (
    <div className="absence-status">
      <div className="status-with-approve">
        {statusType}
        {approveLabel && (
          <div className="approve-status approved">
            <span>Approved</span>
          </div>
        )}
      </div>

      <p>from 15.06. 2021 to 20.06.2021</p>
      <span className="caption">5 days</span>
    </div>
  );
};

export default StatusColumn;
