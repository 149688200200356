import React, {FC} from 'react';
import MaterialTable, {MaterialTableProps} from 'material-table';

import {tableIcons} from './icons';
import {PageLoader} from '../PageLoader/PageLoader';

type ICustomMaterialTableProps = MaterialTableProps<any>;

const CustomTable: FC<ICustomMaterialTableProps> = ({
  isLoading,
  ...restProps
}) => {
  const {columns, components, options, data} = restProps;
  return (
    <div className="material-table-custom">
      <div className="custom-table-holder">
        {isLoading ? (
          <div className="table-loader">
            <PageLoader />
          </div>
        ) : (
          <MaterialTable
            icons={tableIcons}
            isLoading={isLoading}
            columns={columns}
            components={components}
            options={options}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default CustomTable;
