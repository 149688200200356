import React, {FC, forwardRef} from 'react';
import ReactSelect, {NamedProps} from 'react-select';
import {InputLabel} from '@material-ui/core';
import {IValueLabelPair} from '../../interfaces/shared';

export type ISelectProps = NamedProps<IValueLabelPair<string>, any> & {
  label?: string;
  helperText?: string;
  error?: string;
};

export const Select: FC<ISelectProps> = forwardRef(
  (
    {className = '', label = '', placeholder = '', error = '', ...restProps},
    ref,
  ) => {
    return (
      <>
        {label && <InputLabel htmlFor={label as string}>{label}</InputLabel>}
        <ReactSelect
          inputRef={ref}
          classNamePrefix="react-select"
          placeholder={placeholder}
          className={`react-select-container input-crop ${className}`}
          {...restProps}
        />
        {/* TODO */}
        {error && (
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">
            {error}
          </p>
        )}
      </>
    );
  },
);
