import React, {FC, SyntheticEvent} from 'react';
import {Pagination} from '@material-ui/lab';
import {PerPage} from './PerPage';
import {PageLabel} from './PageLabel';

interface PaginationProps {
  perPage: number;
  currentPage: number;
  totalPages?: number;
  total?: number;
  paginationChange: (event: SyntheticEvent<EventTarget>, page: number) => void;
  onPrePageChange: (perPage: number) => void;
}

export const CustomPagination: FC<PaginationProps> = ({
  totalPages,
  currentPage,
  perPage,
  paginationChange,
  onPrePageChange,
  total,
}) => {
  return (
    <div className="pagination-holder">
      <div className="pagination-left">
        <PerPage onPrePageChange={onPrePageChange} perPage={perPage} />
        <PageLabel perPage={perPage} total={total} currentPage={currentPage} />
      </div>
      <Pagination
        defaultPage={1}
        count={totalPages}
        page={currentPage}
        color="primary"
        onChange={paginationChange}
      />
    </div>
  );
};
