import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Sidebar from '../Sidebar/Sidebar';
import {Header} from '../Header/Header';
import clsx from 'clsx';
import {PathNames, UnauthPaths} from 'routing/pathNames';
import {useSelector} from 'react-redux';
import {StoreState} from '../../store/rootReducer';
import {CustomBreadcrumbs} from '../../components/Breadcrumbs/Breadcrumbs';
import {useLocation} from 'react-router-dom';
import {getSidebarState, saveSidebarState} from '../../storage/sidebar';

interface MainPropsI {
  children: React.ReactNode;
  classes?: {[key: string]: any};
  route?: string;
}

const Main: FC<MainPropsI> = ({route, classes, children}): JSX.Element => {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(() => getSidebarState());

  const handleDrawerToggle = useCallback(() => {
    setOpen((prev) => saveSidebarState(!prev));
  }, []);

  const handleDrawerMobileToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, []);

  const pageTitle: string = useSelector((state: StoreState) => {
    return state.pageReducer.pageTitle;
  });

  const showHeaderWithSidebar = !UnauthPaths.has(location.pathname);

  return (
    <div id="wrapper">
      {showHeaderWithSidebar ? (
        <>
          <Header menuClick={handleDrawerMobileToggle} />
          <div
            className={clsx('page-wrap', {
              'sidebar-open': open,
            })}>
            <Sidebar
              menuClick={handleDrawerMobileToggle}
              mobileOpen={mobileOpen}
              sidebarOpen={open}
              sidebarToggle={handleDrawerToggle}
            />
            <div className="content">
              {/*<div className="container">
                {location.pathname !== PathNames.main && (
                  <CustomBreadcrumbs
                    parentLink={pageTitle}
                    parentLinkIcon="icon-nav-item-1"
                  />
                )}
              </div>*/}

              {children}
            </div>
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default memo(Main);
