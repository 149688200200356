import axios, {Canceler} from 'axios';
import client, {API_ROUTES} from '../../http-client';
import {Profile, ProfileLanguage} from '../../interfaces/profile';

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const getProfile = async (): Promise<Profile> => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.profile, {
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  return response.data.data;
};

export const updateProfileLanguage = async (
  language: ProfileLanguage,
): Promise<void> => {
  if (cancel !== undefined) {
    cancel();
  }

  return await client.patch(`${API_ROUTES.profileLanguage}/${language}`, {
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });
};
