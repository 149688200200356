import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import queryString from 'query-string';
import {CredentialsManager} from '../cookies';

interface ClientInterface extends AxiosInstance {
  defaults: AxiosRequestConfig;
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
}

const client: ClientInterface = Axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'https://api-hrms.ddi-dev.com.ua/',
  paramsSerializer: (params) =>
    queryString.stringify(params, {arrayFormat: 'index'}),
  headers: {
    isPublic: false,
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(
  (request: AxiosRequestConfig): AxiosRequestConfig => {
    if (request.headers) {
      if (request.headers.isPublic) {
        delete request.headers.Authorization;
      }
      delete request.headers.isPublic;
    }

    return request;
  },
);

client.interceptors.response.use(undefined, (error: AxiosError):
  | never
  | void => {
  if (Axios.isCancel(error)) {
    throw error;
  }

  if (!error.response) {
    throw error;
  }
  // todo - add refresh token
  if (
    error.response.status === 401 &&
    !window.location.pathname.includes('/login')
  ) {
    CredentialsManager.removeCredentials();
    window.location.href = '/login';
  }

  throw error;
});

export function setToken(token: string): void {
  Object.assign(client.defaults.headers, {
    Authorization: `Bearer ${token}`,
  });
}

export function removeToken(): void {
  delete client.defaults.headers.Authorization;
}

export function getToken(): string | null {
  return client.defaults.headers.Authorization;
}

export default client;
