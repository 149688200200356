import React, {FC, useEffect, useState, useCallback} from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  InputLabel,
} from '@material-ui/core';
import {useForm, Controller} from 'react-hook-form';
import {Modal} from '../Modal';
import {MODALS, useModals} from 'containers/Modal';
import {schema, RequestTimeOffFormSchema} from './validator';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldErrors} from '../../http-client/types';
import {createAbsence} from '../../services/absence';
import Select from 'react-select';
import dayjs from 'dayjs';
import {getEmployees} from '../../services/employee';
import {getAbsenceTypes} from '../../services/absence-type';
const advancedFormat = require('dayjs/plugin/advancedFormat');
import {Datepicker} from '../../components/Datepicker/Datepicker';
import {DropdownIndicator} from '../../components/ChipSelect/IconSelect';
import {SingleValue} from '../../components/ChipSelect/SingleValue';
import useAPIOptions from '../../hooks/useAPIOptions';
import {useTranslation} from 'react-i18next';
dayjs.extend(advancedFormat);
interface RequestTimeOffProps {
  open: boolean;
  handleClose: () => void;
  props: {
    updateAbsencesProfile: (requesterId?: string) => Promise<void>;
    userId?: string;
  };
}

// @todo Sergey very large component
export const RequestTimeOff: FC<RequestTimeOffProps> = ({open, props}) => {
  const {t} = useTranslation(['employeeSection']);
  const {closeModal} = useModals();
  const onRequestClose = useCallback(() => closeModal(MODALS.RequestTimeOff), [
    closeModal,
  ]);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver<RequestTimeOffFormSchema>(schema),
  });

  // @todo Sergey create separate hooks for getting the data from the API
  const absenceTypeOptions = useAPIOptions(getAbsenceTypes);
  const requesterOptions = useAPIOptions(getEmployees);

  const [processing, setProcessing] = useState<boolean>(false);
  const [absenceTypeId, setAbsenceTypeId] = useState<string>('');
  const [approversIds, setApproversIds] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date());
  const [dateTo, setDateTo] = useState<Date | null>(new Date());

  const watchType = watch('absenceTypeId');
  const watchApproversIds = watch('approversIds');

  // get option for select requesterId
  useEffect(() => {
    register({name: 'dateFrom', value: dateFrom});
    register({name: 'dateTo', value: dateTo});
  }, []);

  useEffect(() => {
    // select return object {value: string, label: string}
    // @ts-ignore
    const selectedAbsenceTypeId = {...watchType};
    const selectedApproversIds = watchApproversIds;
    setAbsenceTypeId(selectedAbsenceTypeId.value);
    // select return object {value: string, label: string}[]
    // @ts-ignore
    setApproversIds(selectedApproversIds);
  }, [watchType, watchApproversIds]);

  useEffect(() => {
    setValue('dateFrom', dateFrom);
    setValue('dateTo', dateTo);
  }, [dateFrom, dateTo]);

  const handleDateFromChange = (date: Date | null) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: Date | null) => {
    setDateTo(date);
  };

  const onSubmit = async (
    formValues: RequestTimeOffFormSchema,
  ): Promise<void> => {
    try {
      const bodyRequest = {...formValues};
      // transform date to timestamp and transform string to number
      bodyRequest.dateFrom = +dayjs(bodyRequest.dateFrom).format('X');
      bodyRequest.dateTo = +dayjs(bodyRequest.dateTo).format('X');
      bodyRequest.absenceTypeId = absenceTypeId;
      // transform selected requesters from [{value, label}] to [{string}]
      const requestRequesterId: string[] = [];
      if (Array.isArray(approversIds)) {
        approversIds.map((requester: any) => {
          requestRequesterId.push(requester.value);
        });
      }
      bodyRequest.approversIds = requestRequesterId;
      setProcessing(true);
      await createAbsence(bodyRequest);
      onRequestClose();
      await props.updateAbsencesProfile(props.userId);
    } catch (_error: unknown) {
      const error = _error as FieldErrors<RequestTimeOffFormSchema>;
      console.log(error);
      return;
    } finally {
      setProcessing(false);
    }
  };
  return (
    <Modal
      fullHeight={true}
      aria-labelledby="modal-title"
      open={open}
      onClose={onRequestClose}
      withCloseButton={true}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          {t('employeeSection:card.requestTimeOff')}
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content">
          <div className="form-control input-row">
            <InputLabel htmlFor="absenceTypeId">
              {t('employeeSection:modal.typeOfAbsence')}
            </InputLabel>
            <Controller
              as={
                <Select
                  inputRef={register}
                  options={absenceTypeOptions.options}
                  name="absenceTypeId"
                  placeholder=""
                  className={
                    errors.absenceTypeId?.message
                      ? 'select-errors react-select-container input-crop'
                      : 'react-select-container input-crop'
                  }
                  isLoading={absenceTypeOptions.loading}
                  components={{DropdownIndicator, SingleValue}}
                  classNamePrefix="react-select"
                />
              }
              defaultValue=""
              name="absenceTypeId"
              control={control}
              helperText={errors.absenceTypeId?.message}
            />
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {errors.absenceTypeId?.message}
            </p>
          </div>
          <div className="input-row">
            <div className="input-row">
              <InputLabel htmlFor="dateFrom">
                {t('employeeSection:modal.dateFrom')}
              </InputLabel>
              <Datepicker
                id="dateFrom"
                selectedDate={dateFrom}
                handleDateChange={handleDateFromChange}
              />
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                {errors.dateFrom?.message}
              </p>
            </div>
            <div className="form-control input-row">
              <InputLabel htmlFor="dateTo">
                {t('employeeSection:modal.dateTo')}
              </InputLabel>
              <Datepicker
                id="dateTo"
                selectedDate={dateTo}
                handleDateChange={handleDateToChange}
              />
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                {errors.dateTo?.message}
              </p>
            </div>
          </div>
          <div className="form-control input-row">
            <InputLabel htmlFor="requesterId">
              {t('employeeSection:modal.chooseApprovers')}
            </InputLabel>
            <Controller
              as={
                <Select
                  inputRef={register}
                  options={requesterOptions.options}
                  isMulti={true}
                  name="approversIds"
                  placeholder=""
                  components={{DropdownIndicator}}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={true}
                  isLoading={requesterOptions.loading}
                  className={
                    errors.approversIds?.message
                      ? 'select-errors react-select-container react-select-container-multiple'
                      : 'react-select-container react-select-container-multiple'
                  }
                  classNamePrefix="react-select"
                />
              }
              defaultValue=""
              name="approversIds"
              control={control}
            />
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {errors.approversIds?.message}
            </p>
          </div>
          <div className="form-control input-row">
            <InputLabel htmlFor="Comment">
              {t('employeeSection:modal.comment')}
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              id="standard-multiline-static"
              multiline
              inputRef={register}
              helperText={errors.comment?.message}
              error={!!errors.comment?.message}
              name="comment"
              rows={7}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button variant="outlined" disableRipple onClick={onRequestClose}>
            {t('employeeSection:modal.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disableRipple
            disabled={processing}
            color="primary">
            {processing ? (
              <CircularProgress color={'inherit'} size={20} />
            ) : (
              t('employeeSection:modal.SendRequest')
            )}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
