import {AnyAction} from 'redux';
import {ProfileActions} from './actions';
import {Profile} from '../../interfaces/profile';

export interface UserProfileReducerState {
  userProfile?: Profile;
  loading: boolean;
}
const INITIAL_STATE: UserProfileReducerState = {
  userProfile: undefined,
  loading: false,
};

export const userProfileReducer = (
  state: UserProfileReducerState = INITIAL_STATE,
  action: AnyAction,
): UserProfileReducerState => {
  switch (action.type) {
    case ProfileActions.GET_USER_PROFILE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ProfileActions.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
      };
    case ProfileActions.SET_USER_PROFILE_ABSENCES_REQUESTS:
      const updatedUserProfile: any = {...state.userProfile};
      updatedUserProfile.absencesRequests = action.payload;
      return {
        ...state,
        userProfile: updatedUserProfile,
        loading: false,
      };
    default:
      return state;
  }
};
