import React, {ChangeEventHandler, FC, useCallback, useState} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button, InputLabel, TextField} from '@material-ui/core';
import {APIError, StatusCodes} from 'http-client/types';
import {VacationCoefficientI} from 'interfaces/vacationCoefficient';
import {useTranslation} from 'react-i18next';

type CreateCoefficientProps = {
  open: boolean;
  updateData: () => void;
};

export const CreateCoefficient: FC<CreateCoefficientProps> = ({
  open,
  updateData,
}): JSX.Element => {
  const {closeModal} = useModals();
  const {t} = useTranslation(['settings', 'general']);

  const [year, setYear] = useState<number>();
  const [days, setDays] = useState<number>();
  const [error, setError] = useState<string>('');

  const handleChangeYear: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    const numVal = Number(event.target.value);
    setYear(isNaN(numVal) ? undefined : numVal);
  };

  const handleChangeDays: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    const numVal = Number(event.target.value);
    setDays(isNaN(numVal) ? undefined : numVal);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      // await createCoefficient({name});

      onClose();
      updateData();
    } catch (_error: unknown) {
      const error = _error as APIError<VacationCoefficientI>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setError(error.response.data.error);
        return;
      }
    }
  };

  const onClose = useCallback(() => closeModal(MODALS.CreateCoefficient), [
    closeModal,
  ]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          {t('vacationCoefficientTab:createCoefficient')}
        </h3>
      </div>
      <div className="modal-content">
        <div className="form-control input-row">
          <InputLabel htmlFor="year">
            {t('vacationCoefficientTab:numberOfYear')}
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="year"
            name="year"
            value={year}
            onChange={handleChangeYear}
          />
        </div>
        <div className="form-control input-row">
          <InputLabel htmlFor="days">
            {t('vacationCoefficientTab:countOfDay')}
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="days"
            name="days"
            value={days}
            onChange={handleChangeDays}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button disableRipple onClick={onClose}>
          {t('terms:cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={handleSubmit}>
          {t('vacationCoefficientTab:addCoefficient')}
        </Button>
      </div>
    </Modal>
  );
};
