import {STORAGE_PREFIX} from './configuration';

export const getSidebarState = (): boolean => {
  const cachedState = localStorage.getItem(`${STORAGE_PREFIX}:isSidebarOpen`);

  return cachedState !== null ? !(cachedState === 'false') : true;
};

export const saveSidebarState = (state: boolean): boolean => {
  localStorage.setItem(`${STORAGE_PREFIX}:isSidebarOpen`, state.toString());

  return state;
};
