import {combineReducers} from 'redux';
import {authReducer, AuthReducerState} from './auth';
import {pageReducer, PageReducerState} from './page';
import {userProfileReducer, UserProfileReducerState} from './profile';

export interface StoreState {
  authReducer: AuthReducerState;
  pageReducer: PageReducerState;
  userProfileReducer: UserProfileReducerState;
}

const rootReducer = combineReducers<StoreState>({
  authReducer,
  pageReducer,
  userProfileReducer,
});

export default rootReducer;
