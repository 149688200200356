import React from 'react';
import classNames from 'classnames';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  spaceLg?: boolean;
  paddingFree?: boolean;
}

type TabsProps = {
  value?: any;
  index: number;
  children?: React.ReactNode;
  spaceLg?: boolean;
  paddingFree?: boolean;
};

function TabPanel(props: TabPanelProps) {
  const {children, value, index, spaceLg, paddingFree, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <div
          className={classNames('tab-content-wrapper', {
            'padding-lg': spaceLg,
            'padding-free': paddingFree,
          })}>
          {children}
        </div>
      )}
    </div>
  );
}

export default function DefaultTabContent(props: TabsProps) {
  const {value, index, children, spaceLg, paddingFree} = props;

  return (
    <>
      <TabPanel
        value={value}
        index={index}
        spaceLg={spaceLg}
        paddingFree={paddingFree}>
        {children}
      </TabPanel>
    </>
  );
}
