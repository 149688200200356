import React, {useMemo} from 'react';
import {Column} from 'material-table';
import StatusColumn from './columns/StatusColumn';
import RequestedDateColumn from './columns/RequestedDateColumn';
import ApprovesColumn from './columns/ApprovesColumn';
import CustomTable from '../../../../components/CustomMaterialTable/CustomMaterialTable';
import CustomTablePagination from '../../../../components/CustomMaterialTable/CustomTablePagination';
import {Button} from '@material-ui/core';
import EmployeeColumn from './columns/EmployeeColumn';
import {MODALS, useModals} from '../../../../containers';

const AbsencesForHRTableHistory = () => {
  const {openModal} = useModals();

  const handleRequestChangeStatus = (): void => {
    openModal(MODALS.RequestChangeStatus);
  };
  const columns: Column<any>[] = useMemo(
    () => [
      {
        title: 'Employee',
        render: () => <EmployeeColumn />,
      },
      {
        title: 'Type of absence',
        render: () => <StatusColumn approveLabel />,
      },
      {
        title: 'Comment',
        render: () => (
          <div className="comment-text">
            <p className="caption">
              Прошу предоставить мне отпуск без сохранения заработной платы. В
              связи с семейными обстоятельствами.
            </p>
          </div>
        ),
      },
      {
        title: 'Requested date',
        render: () => <RequestedDateColumn />,
      },
      {
        title: 'Balance by absence',
        render: () => (
          <div className="employee-data">
            <p className="text-medium">5 d. until 17.06.2021</p>
            <p>10 d. allowed for now</p>
            <p>10 d. allowed for now</p>
            <p>10 d. allowed at 15.06.2021</p>
            <div className="more-info">
              <p className="more-info__button">more...</p>
              <div className="more-info__dropdown">
                <p className="more-info__title">Vacation</p>
                <p className="text-medium">5 d. until 17.06.2021</p>
                <p>Total: 20 days per year</p>
                <p>Accrued per month: 1.67 day</p>
                <p>Used: 0 days</p>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Approves',
        render: () => <ApprovesColumn />,
      },
      {
        title: '',
        render: () => (
          <div className="button-column">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleRequestChangeStatus}>
              Change status
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const data = [{employee: 'Name'}];

  const components = useMemo(
    () => ({
      Toolbar: () => null,
    }),
    [],
  );

  /*const components = useMemo(
    () => ({
      Toolbar: () => null,
      Pagination: () => (
        <CustomTablePagination
          pagination={pagination}
          onPrePageChange={(newPerPage) =>
            updatePagination('perPage', newPerPage)
          }
          paginationChange={(_, newPage) => updatePagination('page', newPage)}
        />
      ),
    }),
    [updatePagination, pagination],
  );*/

  return <CustomTable columns={columns} components={components} data={data} />;
};

export default AbsencesForHRTableHistory;
