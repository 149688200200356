import React, {FC} from 'react';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';

export type DatepickerProps = KeyboardDatePickerProps;

export const Datepicker: FC<DatepickerProps> = (props) => {
  return (
    <KeyboardDatePicker
      variant="inline"
      inputVariant="outlined"
      className="input-crop"
      format="MMMM dd, yyyy"
      margin="none"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...props}
    />
  );
};
