import {
  ClipboardEventHandler,
  FC,
  KeyboardEventHandler,
  MutableRefObject,
  useMemo,
} from 'react';
import {TextField, TextFieldProps} from '@material-ui/core';
import {InputType} from '../../constants/input';

type SingleDigitInputProps = TextFieldProps & {
  refs: Array<MutableRefObject<HTMLElement | null>>;
  index: number;
  blurOnInput?: boolean;

  state: Array<[string, (newValue: string) => void]>;

  // setValue: (digit: string) => void;
  setError: (error: string) => void;
};

export const SingleDigitInput: FC<SingleDigitInputProps> = ({
  blurOnInput,
  refs,
  index,
  state,
  // setValue,
  setError,
  ...props
}): JSX.Element => {
  const [value, setValue] = useMemo(() => {
    return state[index];
  }, [state, index]);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const key = event.key;

    if (key.match(/\d/)?.length) {
      setValue(key);
      props.error && setError('');

      if (index === refs.length - 1) {
        // if (typeof refs[index] !== 'function' && refs[index]?.current) {
        //   setImmediate(() => {
        //     refs[index]?.current!.blur();
        //   });
        // }

        return;
      }

      if (refs[index + 1]?.current) {
        setImmediate(() => {
          refs[index + 1]?.current!.focus();
        });
      }
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Backspace') {
      setValue('');

      if (index > 0 && refs[index - 1]?.current) {
        setImmediate(() => {
          refs[index - 1].current?.focus();
        });
      }
    }
  };

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    const pastedValue = event.clipboardData
      .getData('text')
      .replace(/\D+/gm, '');

    setValue(pastedValue[0]);

    let inputOffset = 0;
    let inputIndex = index;
    while (inputIndex < state.length && pastedValue[inputOffset]) {
      state[inputIndex][1](pastedValue[inputOffset]);

      inputOffset++;
      inputIndex++;
    }

    if (inputIndex < state.length) {
      if (refs[inputIndex]?.current) {
        setImmediate(() => {
          refs[inputIndex]?.current!.focus();
        });
      }
    } else {
      if (refs[index]?.current) {
        setImmediate(() => {
          refs[index]?.current!.blur();
        });
      }
    }
  };

  return (
    <TextField
      className="number-input"
      type={InputType.number}
      variant="outlined"
      placeholder="X"
      value={value}
      onKeyPress={handleKeyPress}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      inputRef={refs[index]}
      autoFocus={index === 0}
      {...props}
      // id="phone-number-1"
      // error={!!inputError}
    />
  );
};
