import React, {FC, memo} from 'react';
import {Button, CircularProgress} from '@material-ui/core';

interface IModalFooterProps {
  onCancel: () => void;
  isSubmitting: boolean;
  submitTitle?: string;
}

const ModalFooter: FC<IModalFooterProps> = ({
  onCancel,
  submitTitle,
  isSubmitting,
}) => {
  return (
    <div className="modal-footer">
      <Button variant="outlined" disableRipple onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        variant="contained"
        disableRipple
        disabled={isSubmitting}
        color="primary">
        {isSubmitting ? (
          <CircularProgress color={'inherit'} size={20} />
        ) : (
          submitTitle || 'Send request'
        )}
      </Button>
    </div>
  );
};

export default memo(ModalFooter);
