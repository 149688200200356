import axios, {Canceler} from 'axios';
import {APIRequestHandlerGet} from '../../http-client/types';
import client, {API_ROUTES} from '../../http-client';
import {APIRoles} from './types';

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const getRoles: APIRequestHandlerGet<APIRoles> = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.roles, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data.data;
};
