import * as yup from 'yup';
import i18n from '../../i18n/index';

export interface LoginStep1Schema {
  email: string;
  password: string;
}

export interface LoginStep2Schema {
  phone: string;
}

export interface LoginStep3Schema {
  code: string;
}

export interface ForgotPasswordStep1Schema {
  email: string;
}

export interface ForgotPasswordStep2Schema {
  newPassword: string;
  confirmPassword: string;
}

export const schemaStep1 = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email(i18n.t('errors:pleaseCheckMailAddress'))
    .required(i18n.t('errors:requiredField')),
  password: yup.string().nullable().required(i18n.t('errors:requiredField')),
});

// export const schemaStep3 = yup.object().shape({
//   email: yup
//     .string()
//     .nullable()
//     .email('Please check e-mail address')
//     .required('The field is required'),
//   password: yup.string().nullable().required('The field is required'),
// });

export const schemaForgotPasswordStep1 = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email(i18n.t('errors:pleaseCheckMailAddress'))
    .required(i18n.t('errors:requiredField')),
});

export const schemaForgotPasswordStep2 = yup.object().shape({
  newPassword: yup.string().required(i18n.t('errors:requiredField')),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], i18n.t('errors:passwordsMustMatch'))
    .required(i18n.t('errors:requiredField')),
});
