import React, {FC, useEffect, useRef, useState} from 'react';
import logo from 'images/logo-ddi-hrms.svg';
import {Button} from '@material-ui/core';
import {APIError, StatusCodes} from '../../http-client/types';
import {SingleDigitInput} from '../../shared/SingleDigitInput';
import {sendSmsCodeApi} from 'services';
import {LoginStep2Schema} from './validator';
import {useTranslation} from 'react-i18next';

const numberInputsDummy = [null, null, null];

interface IStep2 {
  nextStep: (digits: string) => void;
}

const Step2: FC<IStep2> = ({nextStep}): JSX.Element => {
  const {t} = useTranslation(['login']);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>('');
  const digitValues = [
    useState<string>(''),
    useState<string>(''),
    useState<string>(''),
  ];
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (digitValues.some(([value]) => !value)) {
      setInputError(t('login:step2.EnterLastDigits'));
      return;
    }

    setInputError('');
    const digits = `${digitValues[0][0]}${digitValues[1][0]}${digitValues[2][0]}`;

    try {
      setLoading(true);

      await sendSmsCodeApi({phone: digits});
    } catch (_error: unknown) {
      const error = _error as APIError<LoginStep2Schema>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setInputError(error.response.data.error);
        return;
      }

      setInputError(error.response.data.message);
      return;
    } finally {
      setLoading(false);
    }

    nextStep(digits);
  };

  return (
    <div className="login-holder step">
      <form className="login-form" onSubmit={handleSubmit}>
        <img
          src={logo}
          alt={t('forgotPassword:DDIDevelopment')}
          className="logo"
        />
        <p className="form-label">{t('login:step2.EnterLastNumbers')}</p>

        <div className="form-row number-row">
          <div className="form-wrapper-control">
            {numberInputsDummy.map((_, index) => {
              const id = `phone-number-${index}`;
              return (
                <SingleDigitInput
                  key={id}
                  id={id}
                  state={digitValues}
                  setError={setInputError}
                  error={!!inputError}
                  refs={inputRefs}
                  index={index}
                />
              );
            })}

            {!!inputError && <span className="error-text">{inputError}</span>}
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-container">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}>
              {t('login:step2.send')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step2;
