import React, {FC, useContext, useEffect} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {PathNames} from './pathNames';
import {ProtectedRouteType} from './types';
import {AuthContext, UserProfileContext} from '../containers';

type ProtectedRouteProps = ProtectedRouteType;

export const RouteResolver: FC<ProtectedRouteProps> = ({
  Component,
  guard,
  roles,
  ...rest
}) => {
  const {authorized, user} = useContext(AuthContext);

  const addAuthorize = (
    Component: React.ComponentType,
  ): React.ComponentType => {
    const Redirect = () => {
      const history = useHistory();
      useEffect(() => {
        history.push(authorized ? PathNames.main : PathNames.login);
      }, [history]);

      return null;
    };
    if (guard) {
      if (authorized) {
        if (user && roles) {
          let hasPermission = false;
          roles?.map((role) => {
            if (user.role?.includes(role)) {
              hasPermission = true;
            }
          });
          return hasPermission ? Component : Redirect;
        }
        return Component;
      } else {
        return Redirect;
      }
    }
    return Component;
  };

  const EnhancedComponent = addAuthorize(Component);

  return (
    <Route {...rest}>
      <EnhancedComponent />
    </Route>
  );
};
