import React from 'react';

const RequestedDateColumn = () => {
  return (
    <div className="requested-column">
      <p className="requested-column__date">12.06.2021</p>
      <span className="caption">(2 days before)</span>
    </div>
  );
};

export default RequestedDateColumn;
