import React, {FC, useCallback, useContext, useRef, useState} from 'react';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import {useHistory} from 'react-router';
import {AuthContext} from '../../../containers';
import {useTranslation} from 'react-i18next';

type ProfileDropdownMenuProps = {
  avatar: string;
  name: string;
  email: string;
};

export const ProfileDropdownMenu: FC<ProfileDropdownMenuProps> = ({
  avatar,
  name,
  email,
}) => {
  const history = useHistory();
  const {logout} = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const {t} = useTranslation(['header']);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseAndGoToProfile = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    history.push('/profile');
    setOpen(false);
  };
  const handleCloseAndLogout = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    logout();
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'profile-actions' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple
        className="profile-dropdown-menu">
        <Avatar alt="Remy Sharp" src={avatar} />
        <span className="user-name">{name}</span>
        <i className="icon-arrow-drop-down" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className="dropdown-menu"
        disablePortal>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper className="dropdown-holder">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="dropdown-list"
                  autoFocusItem={open}
                  id="profile-actions"
                  onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleCloseAndGoToProfile}>
                    <div className="menu-item">
                      <span>{t('header:profile')}</span>
                      <i className="icon-account menu-item__icon-lg" />
                    </div>
                  </MenuItem>
                  <hr className="menu-divider" />
                  <MenuItem onClick={handleCloseAndLogout}>
                    <div className="menu-item">
                      <span>{t('header:logOut')}</span>
                      <i className="icon-logout menu-item__icon-lg" />
                    </div>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
