import React, {FC} from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import IconsAbsence from '../Icon/IconsAbsence';

type EmployeeInfoProps = {
  iconClass?: string;
  text?: string;
  to?: number;
  from?: number;
  classes?: string;
  subtext?: string;
};

export const EmployeeInfo: FC<EmployeeInfoProps> = ({
  iconClass,
  text,
  to,
  from,
  classes,
  subtext,
}) => {
  const status = `${iconClass === 'vacation' ? 'approved' : ' '} 
          ${iconClass === 'day-off' ? 'pending' : ' '}  
          ${iconClass === 'sick-leave' ? 'declined' : ''} `;

  if (iconClass) {
    return (
      <p className={clsx(classes)}>
        {iconClass && <IconsAbsence type={iconClass} status={status.trim()} />}{' '}
        {text}
        {' - '}
        {to && (
          <span className="employee-info-card__date">
            {dayjs.unix(Number(from)).format('DD.MM.YYYY')} -{' '}
            {dayjs.unix(Number(to)).format('DD.MM.YYYY')}
          </span>
        )}
      </p>
    );
  }

  return null;
};
