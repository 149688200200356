import React, {FC, memo, useCallback, useEffect, useState} from 'react';

import EmployeesTable from './components/table/EmployeesTable';
import EmployeesToolbar from './components/EmployeesToolbar';
import {TabsHeader} from '../../components/Tabs/TabsHeader';
import {Tab} from '@material-ui/core';
import DefaultTabContent from '../../components/Tabs/Tabs';
import {PaginationWithTotal} from '../../http-client/types';
import {initialPaginationParams} from '../../http-client';
import {EmployeesDataRequestFn} from '../../constants';

const EmployeesForHR: FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabPagination, setTabPagination] = useState<PaginationWithTotal>(
    initialPaginationParams,
  );
  const [inputValue, setInputValue] = useState(tabPagination.search);
  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setTabIndex(newValue);
    },
    [],
  );

  const a11yProps = (index: any) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <div className="container">
      <EmployeesToolbar
        pagination={tabPagination}
        value={inputValue}
        setValue={setInputValue}
      />

      <div className="table-tab-header">
        <TabsHeader value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Current employees" {...a11yProps(0)} />
          <Tab label="Incoming employees" {...a11yProps(1)} />
          <Tab label="Archived employees" {...a11yProps(2)} />
        </TabsHeader>
      </div>
      {Object.keys(EmployeesDataRequestFn).map((tab, index) => (
        <DefaultTabContent
          value={tabIndex}
          index={Number(tab)}
          paddingFree
          key={`employee-tab-${tab}`}>
          <EmployeesTable
            activeTab={tabIndex}
            searchValue={inputValue}
            noBoxShadow
          />
        </DefaultTabContent>
      ))}
    </div>
  );
};

export default memo(EmployeesForHR);
