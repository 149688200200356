import {AnyAction} from 'redux';
import {Profile} from '../../interfaces/profile';

export enum ProfileActions {
  GET_USER_PROFILE = 'GET_USER_PROFILE',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  SET_USER_PROFILE_ABSENCES_REQUESTS = 'SET_USER_PROFILE_ABSENCES_REQUESTS',
}

export function setUserProfile(payload: Profile): AnyAction {
  return {
    type: ProfileActions.SET_USER_PROFILE,
    payload,
  };
}

export function setUserProfileAbsencesRequests(payload: string): AnyAction {
  return {
    type: ProfileActions.SET_USER_PROFILE_ABSENCES_REQUESTS,
    payload,
  };
}
