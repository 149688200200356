import React, {FC, memo} from 'react';
import {IEmployeeColumnProps} from '../../../type';
import {CopyToClipboardEmail} from '../../../../../components/CopyToClipboardEmail';

const ContactsColumn: FC<IEmployeeColumnProps> = ({employee}) => {
  return (
    <div className="employee-data">
      <CopyToClipboardEmail
        classes="subtext text-overflow"
        email={employee.email}
      />
      <p>{employee.phone}</p>
    </div>
  );
};

export default memo(ContactsColumn);
