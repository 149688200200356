import React, {FC} from 'react';
import clsx from 'clsx';

type WidgetHolderProps = {
  classes?: string;
};

const WidgetHolder: FC<WidgetHolderProps> = ({children, classes}) => {
  return <div className={clsx('widget', classes)}>{children}</div>;
};

export default WidgetHolder;
