import React, {forwardRef} from 'react';
import {InputLabel, TextField, TextFieldProps} from '@material-ui/core';

export type ITextInputProps = TextFieldProps;

export const TextInput = forwardRef(
  ({label, ...restProps}: ITextInputProps, ref) => {
    return (
      <>
        {label && <InputLabel htmlFor={label as string}>{label}</InputLabel>}
        <TextField
          variant="outlined"
          type="text"
          inputRef={ref}
          {...restProps}
        />
      </>
    );
  },
);
