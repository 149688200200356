import React, {FC, useCallback} from 'react';
import {Button, TextField, InputLabel} from '@material-ui/core';
import {Modal} from '../Modal';
import {MODALS, useModals} from 'containers/Modal';
import dayjs from 'dayjs';
const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface RequestApproveProps {
  open: boolean;
  handleClose: () => void;
}

export const RequestApprove: FC<RequestApproveProps> = ({open}) => {
  const {closeModal} = useModals();
  const onRequestClose = useCallback(() => closeModal(MODALS.RequestApprove), [
    closeModal,
  ]);

  return (
    <Modal
      fullHeight={true}
      aria-labelledby="modal-title"
      open={open}
      onClose={onRequestClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          <i className="icon-vacation icon-green" />
          Request vacation
        </h3>
      </div>
      <form>
        <div className="modal-content">
          <p className="subtext text-medium">Requested details:</p>
          <div className="request-data">
            <div className="request-data__row">
              <div className="request-data__type">
                <p className="subtext">Requester:</p>
              </div>
              <div className="request-data__value">
                <p className="subtext">Yegor Salogub</p>
              </div>
            </div>
            <div className="request-data__row">
              <div className="request-data__type">
                <p className="subtext">Type:</p>
              </div>
              <div className="request-data__value">
                <p className="subtext">Vacation</p>
              </div>
            </div>
            <div className="request-data__row">
              <div className="request-data__type">
                <p className="subtext">Duration</p>
              </div>
              <div className="request-data__value">
                <p className="subtext">5 days (15.06.2021 – 20.06.2021)</p>
              </div>
            </div>
            <div className="request-data__row">
              <div className="request-data__type">
                <p className="subtext">Comment:</p>
              </div>
              <div className="request-data__value">
                <p className="subtext">
                  Прошу предоставить мне отпуск без сохранения заработной платы.
                  В связи с семейными обстоятельствами.
                </p>
              </div>
            </div>
          </div>
          <div className="form-control input-row">
            <InputLabel htmlFor="Comment">Comment (optional)</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              id="standard-multiline-static"
              multiline
              name="comment"
              rows={7}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button variant="outlined" disableRipple onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disableRipple
            className="button-success">
            Approve request
          </Button>
        </div>
      </form>
    </Modal>
  );
};
