import React, {FC} from 'react';

type IconsAbsenceProps = {
  type: string;
  status: string;
};

const IconsAbsence: FC<IconsAbsenceProps> = (props: IconsAbsenceProps) => {
  return (
    <>
      <i
        className={`icon-${props.type.toLowerCase()} 
          ${props.status === 'approved' ? 'icon-green' : ' '} 
          ${props.status === 'pending' || !props.status ? 'icon-yellow' : ' '}  
          ${props.status === 'declined' ? 'icon-red' : ''} 
          ${props.type === 'travel' ? 'icon-blue' : ''} 
        `}
      />
    </>
  );
};

export default IconsAbsence;
