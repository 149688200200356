import React from 'react';
import {Avatar} from '@material-ui/core';

const EmployeeColumn = () => {
  return (
    <div className="employee-info-card employee-column">
      <Avatar className="avatar-md border-free" src="">
        J
      </Avatar>
      <div className="employee-info-card__data">
        <p className="employee-info-card__data--name subtext">
          Brooklyn Simmons
        </p>
        <p className="employee-info-card__data--role caption">
          Digital Marketing Manager
        </p>
      </div>
    </div>
  );
};

export default EmployeeColumn;
