import React, {FC, useContext} from 'react';
import {ProfileDropdownMenu} from './ProfileDropdownMenu/ProfileDropdownMenu';
import {Badge, IconButton, TextField} from '@material-ui/core';
import logo from 'images/logo-ddi-white.svg';
import avatar from 'images/avatar-example.svg';
import {UserProfileContext} from '../../containers';

type HeaderProps = {
  menuClick: () => void;
};

export const Header: FC<HeaderProps> = ({menuClick}) => {
  const {profile} = useContext(UserProfileContext);

  return (
    <header id="header">
      <div className="container fullwidth">
        <div className="d-flex justify-content-between align-items-center">
          <div className="menu-box">
            <IconButton
              aria-label="open drawer"
              edge="start"
              className="burger-button"
              onClick={menuClick}>
              <i className="icon-menu" />
            </IconButton>
            <a href="/" className="logo-link">
              <img src={logo} alt="DDI HRMS" />
            </a>
            {/*<h5 className="page-title">{pageTitle}</h5>*/}
          </div>

          <div className="header-actions">
            <div className="header-actions__item">
              <TextField
                id="header-search"
                placeholder="Search..."
                variant="filled"
                InputProps={{
                  startAdornment: <i className="icon-search" />,
                }}
              />
            </div>
            <div className="header-actions__item">
              <Badge
                className="notification-badge"
                badgeContent={4}
                max={9}
                color="primary">
                <i className="icon-notification" />
              </Badge>
            </div>
            <div className="header-actions__item">
              <ProfileDropdownMenu
                avatar={profile.avatar}
                email="cant show email("
                name={`${profile?.firstName} ${profile?.lastName}`}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
