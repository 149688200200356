import {AnyAction} from 'redux';
import {PageActions} from './actions';

export interface PageReducerState {
  pageTitle: string;
}
const INITIAL_STATE = {
  pageTitle: '',
};

export const pageReducer = (
  state: PageReducerState = INITIAL_STATE,
  action: AnyAction,
): PageReducerState => {
  switch (action.type) {
    case PageActions.SET_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };
    default:
      return state;
  }
};
