import {AnyAction} from '../../interfaces/store';
import {AUTH_ACTION} from './actions';
import {CookieUserI, CredentialsManager} from 'cookies';
import {removeToken, setToken} from '../../http-client';

export type AuthReducerState = {
  user?: CookieUserI;
  authorized: boolean;
};

const initialState: AuthReducerState = {
  user: undefined,
  authorized: false,
};

export const reducer = (
  state = initialState,
  action: AnyAction,
): AuthReducerState => {
  switch (action.type) {
    case AUTH_ACTION.login:
      CredentialsManager.setCredentials(
        {
          token: action.payload.token,
          user: action.payload.user,
        },
        action.payload.hasLongTerms,
      );
      setToken(action.payload.token);
      return {
        user: action.payload.user,
        authorized: true,
      };
    case AUTH_ACTION.logout:
      removeToken();
      CredentialsManager.removeCredentials();
      return {...initialState};
    default:
      return initialState;
  }
};

export const getInitialState = (): AuthReducerState => {
  const c = CredentialsManager.getCredentials();

  if (c?.user || c?.token) {
    setToken(c?.token);

    return {user: c?.user, authorized: true};
  }

  return initialState;
};
