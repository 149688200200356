import {API_ROUTES} from '../../http-client/apiRoutes';
import client from '../../http-client/client';
import {APIRequestHandler} from '../../http-client/types';
import {getTFAToken} from 'utils/storage';
import {
  APICheckSMSCode,
  APIForgotPassword,
  APIForgotPasswordChange,
  APIForgotPasswordTokenVerify,
  APILogin,
  APISendSMSCode,
} from './types';

export const loginApi: APIRequestHandler<APILogin> = async (data) => {
  const result = await client.post<{data: APILogin['response']}>(
    API_ROUTES.login,
    data,
  );

  return result.data.data;
};

export const sendSmsCodeApi: APIRequestHandler<APISendSMSCode> = async (
  data,
) => {
  const token = getTFAToken();

  return await client.post(API_ROUTES.sendCode, data, {
    headers: {
      ...client.defaults.headers,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const checkSmsCodeApi: APIRequestHandler<APICheckSMSCode> = async (
  data,
) => {
  const token = getTFAToken();

  const result = await client.post<{data: APICheckSMSCode['response']}>(
    API_ROUTES.checkCode,
    data,
    {
      headers: {
        ...client.defaults.headers,
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return result.data.data;
};

export const recoverPassword: APIRequestHandler<APIForgotPassword> = async (
  data,
) => {
  const result = await client.post<{data: APIForgotPassword['response']}>(
    API_ROUTES.forgotPassword,
    data,
  );

  return result.data.data;
};

export const recoverPasswordVerifyToken: APIRequestHandler<APIForgotPasswordTokenVerify> = async (
  params,
) => {
  const result = await client.get(API_ROUTES.forgotPasswordTokenVerify, {
    params,
  });

  return result.data;
};

export const changePassword: APIRequestHandler<APIForgotPasswordChange> = async (
  data,
) => {
  const result = await client.post<{data: APIForgotPasswordChange['response']}>(
    API_ROUTES.forgotPasswordChange,
    data,
  );

  return result.data.data;
};

export const getRoleApi = async (token: string) => {
  const response = await client.get(API_ROUTES.getRole, {
    headers: {
      ...client.defaults.headers,
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.roles;
};
