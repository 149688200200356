import axios, {Canceler} from 'axios';
import {APIRequestHandler, APIRequestHandlerGet} from '../../http-client/types';
import client, {API_ROUTES} from '../../http-client';
import {
  APIPositionDeleteI,
  APIPositionPostI,
  APIPositionPutI,
  APIPositions,
} from './types';

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const getPositions: APIRequestHandlerGet<APIPositions> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.positions, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const createPosition: APIRequestHandler<APIPositionPostI> = async (
  params,
) => {
  return await client.post(API_ROUTES.positions, params);
};

export const editPosition: APIRequestHandler<APIPositionPutI> = async (
  params,
) => {
  const {id, ...rest} = params;
  return await client.put(`${API_ROUTES.positions}/${id}`, rest);
};

export const deletePosition: APIRequestHandlerGet<APIPositionDeleteI> = async (
  params,
) => {
  if (!params) {
    return;
  }

  return await client.delete(`${API_ROUTES.positions}/${params.id}`);
};
