import axios, {Canceler} from 'axios';
import {APIRequestHandler, APIRequestHandlerGet} from '../../http-client/types';
import client, {API_ROUTES} from '../../http-client';
import {
  APIDepartmentPostI,
  APIDepartmentPutI,
  APIDepartments,
  APIDepartmentDeleteI,
} from './types';

const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const getDepartments: APIRequestHandlerGet<APIDepartments> = async (
  params,
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(API_ROUTES.departments, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const createDepartment: APIRequestHandler<APIDepartmentPostI> = async (
  params,
) => {
  return await client.post(API_ROUTES.departments, params);
};

export const editDepartment: APIRequestHandler<APIDepartmentPutI> = async (
  params,
) => {
  const {id, ...rest} = params;
  return await client.put(`${API_ROUTES.departments}/${id}`, rest);
};

export const deleteDepartment: APIRequestHandlerGet<APIDepartmentDeleteI> = async (
  params,
) => {
  if (!params) {
    return;
  }

  return await client.delete(`${API_ROUTES.departments}/${params.id}`);
};
