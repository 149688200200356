import client, {API_ROUTES} from '../../http-client';
import {APIAbsenceGetI, APIAbsencePostI, APIAbsencePostRequestI} from './types';
import {APIRequestHandler, APIRequestHandlerGet} from '../../http-client/types';
import axios, {Canceler} from 'axios';

export const createAbsence: APIRequestHandler<APIAbsencePostI> = async (
  data: APIAbsencePostRequestI,
) => {
  return await client.post(API_ROUTES.createAbsence, data);
};

const CancelTokenAbsences = axios.CancelToken;
let cancelAbsences: Canceler | undefined;

export const getAbsences: APIRequestHandlerGet<APIAbsenceGetI> = async (
  params: any,
) => {
  if (cancelAbsences !== undefined) {
    cancelAbsences();
  }

  const result = await client.get(API_ROUTES.getAbsences, {
    params,
    cancelToken: new CancelTokenAbsences(function executor(c) {
      cancelAbsences = c;
    }),
  });
  return result.data;
};

const CancelTokenCurrentAbsence = axios.CancelToken;
let cancelCurrentAbsence: Canceler | undefined;

export const getCurrentAbsence: APIRequestHandlerGet<APIAbsenceGetI> = async () => {
  if (cancelCurrentAbsence !== undefined) {
    cancelCurrentAbsence();
  }

  const result = await client.get(API_ROUTES.getCurrentAbsence, {
    cancelToken: new CancelTokenCurrentAbsence(function executor(c) {
      cancelCurrentAbsence = c;
    }),
  });
  return result.data;
};

const CancelTokenCloseVocation = axios.CancelToken;
let cancelCloseVocation: Canceler | undefined;

export const getCloseVocation: APIRequestHandlerGet<APIAbsenceGetI> = async () => {
  if (cancelCloseVocation !== undefined) {
    cancelCloseVocation();
  }

  const result = await client.get(API_ROUTES.getCloseVocation, {
    cancelToken: new CancelTokenCloseVocation(function executor(c) {
      cancelCloseVocation = c;
    }),
  });
  return result.data;
};
