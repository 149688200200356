import React, {FC, useContext, useMemo} from 'react';
import clsx from 'clsx';
import {Button, Drawer, Hidden} from '@material-ui/core';
import {PathNames} from '../../routing/pathNames';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AuthContext} from '../../containers';
import {UserRoles} from '../../interfaces';

const checkPath = (pathname: string) => {
  return location.pathname === pathname;
};

interface ISidebarProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  menuClick: () => void;
  mobileOpen: boolean;
  sidebarOpen: boolean;
  sidebarToggle: () => void;
}

const fullYear = new Date().getFullYear();

const Sidebar: FC<ISidebarProps> = (props): JSX.Element => {
  const {window, menuClick, mobileOpen, sidebarOpen, sidebarToggle} = props;
  const {t} = useTranslation(['sidebar']);
  const location = useLocation();
  const {user} = useContext(AuthContext);
  const MenuItems = useMemo(() => {
    const menuItemsArray = [
      {
        icon: <i className="icon-nav-item-1" />,
        text: t('sidebar:dashboard'),
        link: PathNames.main,
        isActive: checkPath(PathNames.main),
      },
      {
        icon: <i className="icon-nav-item-5" />,
        text: t('sidebar:employees'),
        link: PathNames.employees,
        isActive: checkPath(PathNames.employees),
      },
      // {
      //   icon: <i className="icon-nav-item-2" />,
      //   text: `${t('sidebar:structure')} (WIP)`,
      //   link: PathNames.structure,
      //   isActive: checkPath(PathNames.structure),
      // },
      {
        icon: <i className="icon-nav-item-3" />,
        text: `${t('sidebar:documents')} (WIP)`,
        link: PathNames.documents,
        isActive: checkPath(PathNames.documents),
      },
      {
        icon: <i className="icon-nav-item-4" />,
        text: `${t('sidebar:absences')} (WIP)`,
        link: PathNames.absences,
        isActive: checkPath(PathNames.absences),
      },
    ];
    if (
      user?.role?.length &&
      (user?.role?.includes(UserRoles.admin) ||
        user?.role?.includes(UserRoles.hr))
    ) {
      menuItemsArray.splice(2, 0, {
        icon: <i className="icon-nav-item-5" />,
        text: t('sidebar:employeesForHR'),
        link: PathNames.employeesForHR,
        isActive: checkPath(PathNames.employeesForHR),
      });
      menuItemsArray.splice(menuItemsArray.length - 2, 0, {
        icon: <i className="icon-nav-item-4" />,
        text: `${t('sidebar:absencesForHR')} (WIP)`,
        link: PathNames.absencesForHR,
        isActive: checkPath(PathNames.absencesForHR),
      });
      menuItemsArray.splice(menuItemsArray.length - 1, 0, {
        icon: <i className="icon-settings" />,
        text: `${t('sidebar:settings')} (WIP)`,
        link: PathNames.settings,
        isActive: checkPath(PathNames.settings),
      });
    }
    return menuItemsArray;
  }, [t, location.pathname]);

  const container =
    window !== undefined ? () => window().document.body : undefined;
  if (!user) {
    // @ts-ignore
    return null;
  }
  const drawerContent = (
    <>
      <div className="top-content">
        <Button
          className="sidebar-toggle"
          onClick={mobileOpen ? menuClick : sidebarToggle}
          type="button">
          <i className="icon-arrow-prev" />
        </Button>
        <ul className="sidebar-menu">
          {MenuItems.map((item, index) => {
            return (
              <li
                className={clsx('sidebar-menu__item', {
                  active: item.isActive,
                })}
                key={index}>
                <Link className="sidebar-menu__link" to={item.link}>
                  {item.icon}
                  {(sidebarOpen || mobileOpen) && (
                    <span className="menu-item-text">{item.text}</span>
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <ul className="copyright-list">
        <li>
          <Link to="/">Privacy</Link>
        </li>
        <li>
          <Link to="/">Terms</Link>
        </li>
        <li>
          <span className="copyright">HRMS &copy; {fullYear}</span>
        </li>
      </ul>
    </>
  );

  return (
    <aside className="sidebar">
      <Hidden lgUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          className="drawer"
          open={mobileOpen}
          onClose={menuClick}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx('drawer', {
            'drawer-open': sidebarOpen,
            'drawer-close': !sidebarOpen,
          })}>
          {drawerContent}
        </Drawer>
      </Hidden>
    </aside>
  );
};

export default Sidebar;
