import {IValueLabelPair} from '../interfaces/shared';

export const AccountLanguages: IValueLabelPair<string>[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Russian',
    value: 'ru',
  },
  {
    label: 'Ukrainian',
    value: 'ua',
  },
];
