import React, {createContext} from 'react';
import {Profile} from '../../interfaces/profile';

interface IUserProfileContext {
  profile: Profile;
  updateProfile: () => void;
}

export const initialState: Profile = {
  firstName: '',
  lastName: '',
  avatar: '',
  birthday: 0,
  email: '',
  hireDate: 0,
  employeeId: '',
  absencesRequests: [],
  lang: 'en',
  position: {
    id: '',
    name: '',
  },
  department: {
    id: '',
    name: '',
  },
  profileCounters: {
    vacation: {
      title: '',
      total: 0,
      left: 0,
      close: 0,
      closeDate: 0,
      perMonth: 0,
      used: 0,
    },
    dayOff: {
      title: '',
      total: 0,
      left: 0,
    },
    sickLeave: {
      title: '',
      total: 0,
      left: 0,
    },
  },
};

export const UserProfileContext = createContext<IUserProfileContext>({
  profile: initialState,
  updateProfile: (): void => {
    throw new Error('Not implemented');
  },
});
