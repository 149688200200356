import React, {FC, useCallback} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button} from '@material-ui/core';
import {EmployeeInfoCard} from '../../components/EmployeeCard/EmployeeInfoCard';
import {BirthdaysI} from '../../interfaces/birthdays';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

type BirthdayProps = {
  open: boolean;
  props: {
    modalData: BirthdaysI[];
  };
};

export const Birthday: FC<BirthdayProps> = ({open, props}) => {
  const {t} = useTranslation(['birthdaysSection']);
  const {closeModal} = useModals();

  const onRequestClose = useCallback(() => closeModal(MODALS.Birthday), [
    closeModal,
  ]);

  return (
    <Modal contentScrolled open={open} onClose={onRequestClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">{t('birthdaysSection:title')}</h3>
      </div>
      <div className="modal-content">
        {props.modalData &&
          props.modalData.map((employee: BirthdaysI) => {
            return (
              <EmployeeInfoCard
                key={employee.id}
                avatarSrc={employee.avatar}
                name={employee.name}
                role={employee.position.name}>
                <p className="caption employee-info-card__data--info">
                  {dayjs(employee.birthday).format('DD MMM')}
                </p>
              </EmployeeInfoCard>
            );
          })}
      </div>
      <div className="modal-footer">
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={onRequestClose}>
          {t('birthdaysSection:close')}
        </Button>
      </div>
    </Modal>
  );
};
