const en = Object.assign(
  {},
  require('./en/general.json'),
  require('./en/login.json'),
  require('./en/forgotPassword.json'),
  require('./en/dashboard.json'),
  require('./en/formErrors.json'),
  require('./en/settings.json'),
);

const ru = Object.assign(
  {},
  require('./ru/general.json'),
  require('./ru/login.json'),
  require('./ru/forgotPassword.json'),
  require('./ru/settings.json'),
);

export default {en, ru};
