import React, {FC, useState, useRef, useEffect, useContext} from 'react';
import logo from 'images/logo-ddi-hrms.svg';
import {Button} from '@material-ui/core';
import {checkSmsCodeApi, getRoleApi, sendSmsCodeApi} from 'services';
import {APIError, StatusCodes} from 'http-client/types';
import {LoginStep3Schema} from './validator';
import {SingleDigitInput} from 'shared/SingleDigitInput';
import {useHistory} from 'react-router';
import {AuthContext} from 'containers';
import {useTranslation} from 'react-i18next';

const numberInputsDummy = [null, null, null, null, null, null];

interface IStep3 {
  digits: string;
  hasRememberUser: boolean;
}

const Step3: FC<IStep3> = ({digits, hasRememberUser}): JSX.Element => {
  const {t} = useTranslation(['login']);
  const history = useHistory();
  const {login} = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>('');
  const [timeBeforeResend, setTimeBeforeResend] = useState<number>(0);

  const codeValues = [
    useState<string>(''),
    useState<string>(''),
    useState<string>(''),
    useState<string>(''),
    useState<string>(''),
    useState<string>(''),
  ];

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  useEffect(() => {
    if (timeBeforeResend === 60) {
      const intervalHandle = setInterval(() => {
        setTimeBeforeResend((currentTimer) => {
          if (currentTimer === 0) {
            clearInterval(intervalHandle);
            return currentTimer;
          }

          return currentTimer - 1;
        });
      }, 1000);
    }
  }, [timeBeforeResend]);

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (codeValues.some(([value]) => !value)) {
      setInputError(t('login:step3.incorrectCode'));
      return;
    }

    setInputError('');
    const code = codeValues.reduce<string>(
      (result, [digit, _]) => result + digit,
      '',
    );

    try {
      setLoading(true);
      const result = await checkSmsCodeApi({code});

      if (result) {
        const roleResult = await getRoleApi(result.token);
        login({
          user: {role: roleResult},
          token: result.token,
          hasLongTerms: hasRememberUser,
        });
      }
      history.push('/');
    } catch (_error: unknown) {
      const error = _error as APIError<LoginStep3Schema>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setInputError(error.response.data.error);
        return;
      }

      setInputError(error.response.data.message);
      return;
    } finally {
      setLoading(false);
    }
    // nextStep(digits);
  };

  const handleResend = async (): Promise<void> => {
    await sendSmsCodeApi({phone: digits});
    setTimeBeforeResend(60);
  };

  return (
    <div className="login-holder step">
      <form className="login-form" onSubmit={handleSubmit}>
        <img src={logo} alt="DDI Development" className="logo" />
        <div className="login-form__content">
          <p className="subtext">
            {t('login:step3.smsText')}&nbsp;
            <span className="encoded-phone-number">+380 **** ** {digits}</span>
          </p>
          <p className="form-label mb-14">{t('login:step3.enterCode')}</p>
          <div className="form-row number-row">
            <div className="form-wrapper-control">
              {numberInputsDummy.map((_, index) => {
                const id = `answer-code-number-${index}`;

                return (
                  <SingleDigitInput
                    key={id}
                    id={id}
                    state={codeValues}
                    setError={setInputError}
                    error={!!inputError}
                    refs={inputRefs}
                    index={index}
                  />
                );
              })}

              {!!inputError && <span className="error-text">{inputError}</span>}
            </div>
          </div>
          <div className="form-footer">
            <div className="form-footer-container">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={loading}>
                {t('login:step3.send')}
              </Button>
            </div>
          </div>
          <p className="subtext">
            {t('login:step3.notReceive')}{' '}
            <Button
              color="primary"
              onClick={handleResend}
              disabled={timeBeforeResend > 0 || loading}>
              {timeBeforeResend > 0
                ? `${t('login:step3.codeSent')} ${timeBeforeResend}...`
                : t('login:step3.sendAgain')}
            </Button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Step3;
