import React, {ChangeEventHandler, FC, useCallback, useState} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button, InputLabel, TextField} from '@material-ui/core';
import {createDepartment} from 'services/deparments';
import {APIError, StatusCodes} from 'http-client/types';
import {DepartmentI} from 'interfaces/department';
import {useTranslation} from 'react-i18next';

type CreateDepartmentProps = {
  open: boolean;
  updateData: () => void;
};

export const CreateDepartment: FC<CreateDepartmentProps> = ({
  open,
  updateData,
}): JSX.Element => {
  const {closeModal} = useModals();
  const {t} = useTranslation(['settings', 'general']);

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onClose = useCallback(() => closeModal(MODALS.CreateDepartment), [
    closeModal,
  ]);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setName(event.target.value);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      await createDepartment({name});

      onClose();
      updateData();
    } catch (_error: unknown) {
      const error = _error as APIError<DepartmentI>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setError(error.response.data.error);
        return;
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          {t('departmentsTab:createDepartment')}
        </h3>
      </div>
      <div className="modal-content">
        <div className="form-control input-row">
          <InputLabel htmlFor="department">
            {t('departmentsTab:departmentName')}
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="department"
            name="department"
            value={name}
            onChange={handleChangeName}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button disableRipple onClick={onClose}>
          {t('terms:cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={handleSubmit}>
          {t('departmentsTab:createDepartment')}
        </Button>
      </div>
    </Modal>
  );
};
