import {Tooltip} from '@material-ui/core';
import React, {FC, useEffect, useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import clsx from 'clsx';

type CopyToClipboardEmailProps = {
  email: string;
  classes?: string;
  icon?: React.ReactNode;
};

export const CopyToClipboardEmail: FC<CopyToClipboardEmailProps> = ({
  email,
  classes,
  icon,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const emailToCopy = (
    <p className={clsx('clickable-element', classes)}>
      {icon}
      {email}
    </p>
  );

  let timeout: NodeJS.Timeout;
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect((): void => clearTimeout(timeout), []);

  const handleAfterCopied = (): void => {
    setIsCopied(true);
    timeout = setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <CopyToClipboard text={email} onCopy={handleAfterCopied}>
      <Tooltip open={isCopied} title="Copied" placement="top-start">
        {emailToCopy}
      </Tooltip>
    </CopyToClipboard>
  );
};
