import {AxiosError, AxiosResponse} from 'axios';

export type PaginationParams = {
  page: number;
  perPage: number;
  sort?: string;
  order?: string;
  search?: string;
};

export interface PaginationWithTotal extends PaginationParams {
  totalPages: number;
  total: number;
  [key: string]: any;
}

export interface IPaginationMeta {
  pagination: {
    perPage: number;
    currentPage: number;
    totalPages: number;
    total: number;
  };
}

export interface PaginationParamsCursor {
  page: number;
  per_page: number;
  page_count: number;
  total_count: number;
}

export interface ResponseWithPagination<T> extends PaginationParamsCursor {
  items: Array<T>;
}

export enum StatusCodes {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  ValidationError = 422,
}

export interface SimpleError {
  error: string;
}

export interface FieldErrors<T> {
  message: string;
  errors: {[K in keyof T]: string};
}

export interface APIError400 extends AxiosError {
  response: Omit<AxiosResponse<SimpleError>, 'status'> & {
    status: StatusCodes.BadRequest;
  };
}

export interface APIError401 extends AxiosError {
  response: Omit<AxiosResponse<SimpleError>, 'status'> & {
    status: StatusCodes.Unauthorized;
  };
}

export interface APIError422<T> extends AxiosError {
  response: Omit<AxiosResponse<FieldErrors<T>>, 'status'> & {
    status: StatusCodes.ValidationError;
  };
}

export type APIError<T> = APIError400 | APIError401 | APIError422<T>;

export interface RequestData {
  request: any;
  response: any;
}

export interface RequestDataWithNotRequiredParams {
  request?: any;
  response: any;
}

export interface APIRequest<T extends RequestData> {
  request: T['request'];
  response: T['response'];
  error: APIError<T['request']>;
}

export type APIRequestHandler<T extends RequestData> = (
  data: T['request'],
) => Promise<T['response']>;

export type APIRequestHandlerGet<T extends RequestDataWithNotRequiredParams> = (
  params?: T['request'],
) => Promise<T['response']>;
