import React, {FC, useCallback} from 'react';
import {MODALS, useModals} from '../../containers';
import {Modal} from '../Modal';
import ModalHeader from '../Modal/ModalHeader';
import EmployeeForm from './EmployeeForm';
import {Employee as EmployeeModel} from '../../interfaces/employee';

interface IEmployee {
  open: boolean;
  employee?: EmployeeModel;
}

export const Employee: FC<IEmployee> = ({open = true, employee}) => {
  const {closeModal} = useModals();
  const onRequestClose = useCallback(() => closeModal(MODALS.Employee), [
    closeModal,
  ]);

  return (
    <Modal
      fullHeight={true}
      aria-labelledby="modal-title"
      open={open}
      onClose={onRequestClose}
      withCloseButton={true}>
      <ModalHeader title={employee ? 'Edit Employee' : 'Create Employee'} />
      <EmployeeForm employee={employee} />
    </Modal>
  );
};
