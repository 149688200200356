import React, {FC, memo} from 'react';
import {IEmployeeColumnProps} from '../../../type';
import dayjs from 'dayjs';

const AbsenceColumn: FC<IEmployeeColumnProps> = ({
  employee: {currentAbsence},
}) => {
  const showDate = (date: number): string => {
    return dayjs(date * 1000).format('DD.MM.YYYY');
  };

  return (
    <div className="employee-data">
      {currentAbsence && (
        <>
          <p className="text-with-icon text-semi-bold">
            {currentAbsence.title}
          </p>
          <p className="caption">
            {`${showDate(currentAbsence.from)} - ${showDate(
              currentAbsence.to,
            )}`}
          </p>
        </>
      )}
    </div>
  );
};

export default memo(AbsenceColumn);
