import React, {FC} from 'react';

interface PageLabelProps {
  perPage: number;
  currentPage: number;
  total?: number;
}

export const PageLabel: FC<PageLabelProps> = ({
  currentPage,
  perPage,
  total = 0,
}) => {
  const getPageLabel = (
    total: number,
    perPage: number,
    currentPage: number,
  ) => {
    const start = currentPage * perPage + 1;
    const end = Math.min((currentPage + 1) * perPage, total);

    return `${start} - ${end}`;
  };

  return (
    <p>
      {getPageLabel(total, perPage, currentPage - 1)} of {total}
    </p>
  );
};
