import React from 'react';
import {MuiThemeProvider} from '@material-ui/core';
import {BrowserRouter} from 'react-router-dom';
import Auth from 'containers/Auth/Auth';
import theme from './theme';
import Routes from './routing';
import {Provider} from 'react-redux';
import {getStore} from './store';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Main from './layouts/Main';

function App(): JSX.Element {
  return (
    <Provider store={getStore()}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <I18nextProvider i18n={i18n}>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Auth>
                  <Main>
                    <Routes />
                  </Main>
                </Auth>
              </SnackbarProvider>
            </MuiThemeProvider>
          </I18nextProvider>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
