import {lazy} from 'react';
import {PathNames} from './pathNames';
import {ProtectedRouteType} from './types';
import LoginPage from 'layouts/Login';
import NotFound from 'layouts/NotFound';
import Components from '../pages/Components';
import ForgotPassword from '../layouts/ForgotPassword';
import EmployeesForHR from '../pages/EmployeesForHR';
import AbsencesForHRPage from '../pages/AbsencesForHRPage';
import {UserRoles} from '../interfaces';
const SettingsPage = lazy(() => import('../pages/SettingsPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage'));
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const EmployeePage = lazy(() => import('../pages/EmployeePage'));
const AbsencesPage = lazy(() => import('../pages/AbsencesPage'));
// const LoginPage = lazy(() => import('layouts/Login'));

export const Pages: Array<ProtectedRouteType> = [
  {
    Component: LoginPage,
    path: PathNames.login,
    // roles: [Roles.Admin],
  },
  {
    Component: ForgotPassword,
    path: PathNames.forgotPassword,
    // roles: [Roles.Admin],
  },

  // {Component: NotFound, path: PathNames.notFound},
  {
    Component: DashboardPage,
    path: PathNames.main,
    exact: true,
    guard: true,
  },
  {
    Component: EmployeePage,
    path: PathNames.employees,
    exact: true,
    guard: false,
  },
  {
    Component: EmployeesForHR,
    path: PathNames.employeesForHR,
    exact: true,
    guard: true,
    roles: [UserRoles.admin, UserRoles.hr],
  },
  {
    Component: ProfilePage,
    path: PathNames.profile,
    exact: true,
    guard: true,
  },
  {
    Component: Components,
    path: PathNames.components,
    exact: true,
  },
  {
    Component: SettingsPage,
    path: PathNames.settings,
    exact: true,
    guard: true,
    roles: [UserRoles.admin, UserRoles.hr],
  },
  {
    Component: AbsencesPage,
    path: PathNames.absences,
    exact: true,
  },
  {
    Component: AbsencesForHRPage,
    path: PathNames.absencesForHR,
    exact: true,
    guard: true,
    roles: [UserRoles.admin, UserRoles.hr],
  },
];
