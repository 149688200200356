import React, {FC} from 'react';
import WidgetHolder from '../../components/Widgets/WidgetHolder';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@material-ui/core';
import {DropdownMenu} from '../../components/DropdownMenu/DropdownMenu';

const Components: FC = () => {
  return (
    <>
      <div className="container">
        <WidgetHolder>
          <div className="component-box">
            <h2>TextFields</h2>
            <Grid container direction="row" justify="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="input-row">
                  <InputLabel htmlFor="input-example">Field label</InputLabel>
                  <TextField
                    type="text"
                    id="input-example"
                    variant="outlined"
                    placeholder="Text"
                    fullWidth
                  />
                </div>
                <div className="input-row">
                  <InputLabel htmlFor="input-example-textarea">
                    Field label
                  </InputLabel>
                  <TextField
                    type="text"
                    id="input-example-textarea"
                    variant="outlined"
                    multiline
                    rows={5}
                    placeholder="Text"
                    fullWidth
                    helperText="Simple helper text"
                  />
                </div>
                <div className="input-row">
                  <InputLabel htmlFor="input-example-1">Field label</InputLabel>
                  <TextField
                    error
                    type="text"
                    id="input-example-1"
                    variant="outlined"
                    placeholder="Text"
                    fullWidth
                    helperText="This field is required"
                  />
                </div>
                <div className="input-row">
                  <InputLabel htmlFor="input-example-2">Field label</InputLabel>
                  <TextField
                    type="text"
                    id="input-example-2"
                    variant="outlined"
                    placeholder="Text"
                    fullWidth
                    helperText={
                      <span className="helper-text-success">
                        Field validation is successful.
                      </span>
                    }
                    className="input-success"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="input-row sm">
                  <InputLabel htmlFor="input-example-icon">
                    Field label
                  </InputLabel>
                  <TextField
                    type="text"
                    id="input-example-icon"
                    variant="outlined"
                    placeholder="Text with iconButton"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end">
                            <i className="icon-plus" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="input-row sm">
                  <InputLabel htmlFor="input-example-icon-1">
                    Field label
                  </InputLabel>
                  <TextField
                    type="text"
                    id="input-example-icon-1"
                    variant="outlined"
                    placeholder="Text with simple icon"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="icon-plus" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="component-box">
            <h2>Buttons</h2>
            <Grid container direction="row" justify="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <Button variant="contained" color="primary">
                  Button Text
                </Button>
                <br />
                <br />

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<i className="icon-plus" />}>
                  Button Text
                </Button>
                <br />
                <br />

                <Button
                  variant="contained"
                  color="primary"
                  className="btn-square">
                  <i className="icon-plus" />
                </Button>
                <br />
                <br />

                <Button variant="contained" color="primary" className="btn-low">
                  Button Low
                </Button>
                <br />
                <br />

                <Button
                  variant="contained"
                  color="primary"
                  className="btn-low"
                  startIcon={<i className="icon-plus" />}>
                  Button Low
                </Button>
                <br />
                <br />

                <Button variant="contained" color="primary" disabled>
                  Button Disabled
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="contained" color="secondary">
                  Button Text
                </Button>
                <br />
                <br />

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<i className="icon-plus" />}>
                  Button Text
                </Button>
                <br />
                <br />

                <Button variant="contained" color="secondary" disabled>
                  Button Text
                </Button>
                <br />
                <br />
              </Grid>
            </Grid>
          </div>

          <div className="component-box">
            <h2>Button as select</h2>
            <Grid container direction="row" justify="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="input-row">
                  <InputLabel>Select label</InputLabel>
                  <DropdownMenu sm />
                </div>
                <div className="input-row">
                  <InputLabel>Select label</InputLabel>
                  <DropdownMenu />
                </div>
                <div className="input-row">
                  <InputLabel>Select label</InputLabel>
                  <DropdownMenu fullwidth iconStart="icon-plus" />
                </div>
              </Grid>
            </Grid>
          </div>
        </WidgetHolder>
      </div>
    </>
  );
};

export default Components;
