import React, {FC} from 'react';
import {Avatar} from '@material-ui/core';

type EmployeeInfoCardProps = {
  avatarSrc?: string;
  name: string;
  role: string;
};

export const EmployeeInfoCard: FC<EmployeeInfoCardProps> = ({
  children,
  avatarSrc,
  name,
  role,
}) => {
  return (
    <div className="employee-info-card">
      <Avatar className="avatar-md border-free" src={avatarSrc}>
        {/*{name?.slice(0, 1)}*/}
      </Avatar>
      <div className="employee-info-card__data">
        <p className="employee-info-card__data--name subtext">{name}</p>
        <p className="employee-info-card__data--role caption">{role}</p>
        {children}
      </div>
    </div>
  );
};
