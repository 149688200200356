import React, {FC, useCallback, useState} from 'react';
import logo from 'images/logo-ddi-hrms.svg';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
} from '@material-ui/core';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {LoginStep1Schema, schemaStep1} from './validator';
import {loginApi} from '../../services';
import {APIError, StatusCodes} from 'http-client/types';
import {InputType} from '../../constants';
import {setTFAToken} from 'utils/storage';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';

interface IStep1 {
  nextStep: () => void;
  handleChangeHasRememberUser: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  hasRememberUser: boolean;
}

const Step1: FC<IStep1> = ({
  nextStep,
  hasRememberUser,
  handleChangeHasRememberUser,
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    formState: {isSubmitting},
  } = useForm({
    resolver: yupResolver<LoginStep1Schema>(schemaStep1),
  });
  const {t} = useTranslation(['login']);
  const [generalError, setGeneralError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const passwordType = showPassword ? InputType.text : InputType.password;

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const onSubmit = async (formValues: LoginStep1Schema): Promise<void> => {
    try {
      const result = await loginApi(formValues);

      setTFAToken(result.token);
    } catch (_error: unknown) {
      const error = _error as APIError<LoginStep1Schema>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setGeneralError(error.response.data.error);
        return;
      }

      Object.entries(error.response.data.errors).forEach(
        ([fieldName, message]) => {
          setError(fieldName as keyof LoginStep1Schema, {
            message: message as string,
            type: 'required',
          });
        },
      );

      return;
    }

    nextStep();
  };

  return (
    <div className="login-holder">
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="welcome-text">{t('login:step1.welcome')}</h3>
        <img
          src={logo}
          alt={t('forgotPassword:DDIDevelopment')}
          className="logo"
        />

        {generalError && (
          <div className="form-row error-row">
            <span>{generalError}</span>
          </div>
        )}

        <div className="form-row">
          <InputLabel htmlFor="email">{t('login:step1.emailLabel')}</InputLabel>
          <TextField
            variant="outlined"
            id="email"
            name="email"
            inputRef={register}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
          />
        </div>
        <div className="form-row">
          <InputLabel htmlFor="password">
            {t('login:step1.password')}
          </InputLabel>
          <div className="password-toggler-wrap">
            <TextField
              variant="outlined"
              id="password"
              name="password"
              type={passwordType}
              autoComplete="current-password"
              inputRef={register}
              error={!!errors.password?.message}
              helperText={errors.password?.message}
            />
            <IconButton
              onClick={handleShowPassword}
              className="password-toggler-wrap__button"
              aria-label="show-hide password">
              <i
                className={classNames({
                  'icon-eye': showPassword,
                  'icon-eye-outlined': !showPassword,
                })}
              />
            </IconButton>
          </div>
        </div>
        <div className="form-row row-center">
          <FormControlLabel
            control={
              <Checkbox
                icon={<span className="checkbox-icon" />}
                checkedIcon={<span className="checkbox-icon checked" />}
                checked={hasRememberUser}
                onChange={handleChangeHasRememberUser}
                name="checkedRememberMe"
              />
            }
            label={t('login:step1.rememberMe')}
          />
          <Button
            color="primary"
            type="button"
            component={Link}
            to="/forgot-password">
            {t('login:step1.forgotPassword')}
          </Button>
        </div>

        <div className="form-footer">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isSubmitting}>
            {t('login:step1.logIn')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Step1;
