import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translations from './tranlsations';

i18n.use(initReactI18next).init({
  ns: [
    'login',
    'header',
    'sidebar',
    'employeeSection',
    'absencesSection',
    'birthdaysSection',
    'errors',
    'departmentsTab',
    'positionsTab',
    'absencesTab',
    'vacationCoefficientTab',
  ],
  defaultNS: 'general',
  resources: {
    ...Object.entries(translations).reduce(
      (acc, [key, value]) => ({...acc, [key]: {...value}}),
      {},
    ),
  },
  lng: 'en',
  fallbackLng: 'en',
});

export default i18n;
