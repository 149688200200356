import React, {FC} from 'react';
import {InputLabel} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';

type DatepickerProps = {
  id?: string;
  selectedDate: Date | null;
  handleDateChange: (date: Date | null) => void;
  min?: Date | null;
  disableToolbar?: boolean;
  error?: string;
  label?: string;
};

export const Datepicker: FC<DatepickerProps> = ({
  id,
  selectedDate,
  handleDateChange,
  min,
  disableToolbar = true,
  error,
  label,
}) => {
  return (
    <>
      {label && <InputLabel htmlFor={label as string}>{label}</InputLabel>}
      <KeyboardDatePicker
        disableToolbar={disableToolbar}
        variant="inline"
        inputVariant="outlined"
        className="input-crop"
        format="MMMM dd, yyyy"
        margin="none"
        id={id}
        value={selectedDate}
        onChange={handleDateChange}
        minDate={min}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      {/* TODO */}
      {error && (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">
          {error}
        </p>
      )}
    </>
  );
};
