import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import queryString from 'query-string';

import useLazyQuery from './useLazyQuery';
import {
  EmployeeRequest,
  EmployeeResponse,
  IEmployeePagination,
  UpdateData,
  UpdatePagination,
} from '../pages/EmployeesForHR/type';
import {PaginationParams, PaginationWithTotal} from '../http-client/types';
import {initialPaginationParams} from '../http-client';
import {useHistory} from 'react-router-dom';
import useDebounce from './useDebounce';
// import {initialPaginationParams} from '../pages/EmployeesForHR/constants';
import {Employee} from '../interfaces/employee';
import {APIEmployee} from '../services/employee/types';

const getInitialPaginationParams = (search: string): PaginationWithTotal => {
  const params = queryString.parse(search);

  return Object.keys(initialPaginationParams).reduce((acc, key) => {
    if (params[key]) {
      acc[key] =
        typeof acc[key] === 'number'
          ? parseInt(params[key] as any)
          : params[key];
    }

    return acc;
  }, initialPaginationParams);
};

export interface PaginatedEmployeesResult {
  list: Employee[];
  updatePagination: UpdatePagination;
  pagination: PaginationWithTotal;
  isLoading: boolean;
  updateData: UpdateData;
}
export type usePaginatedEmployeesQueryProps = (
  params: APIEmployee['request'] | undefined,
) => Promise<unknown>;

const usePaginatedEmployees = (
  requestFunction: usePaginatedEmployeesQueryProps,
): PaginatedEmployeesResult => {
  const [getEmployees, {data, isLoading}] = useLazyQuery<
    EmployeeResponse,
    PaginationParams
  >(requestFunction);
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationWithTotal>(() =>
    getInitialPaginationParams(history.location.search),
  );
  const isMount = useRef(true);

  const updateData = useDebounce(() => {
    updateQueryParams();
    const {total, totalPages, ...restProps} = pagination;

    getEmployees({...restProps});
  });

  const updatePagination: UpdatePagination = useCallback((key, value): void => {
    setPagination((prev) => ({...prev, [key]: value}));
  }, []);

  const updateQueryParams = (): void => {
    history.replace(
      `${history.location.pathname}?${queryString.stringify({...pagination})}`,
    );
  };

  useEffect(() => {
    if (isMount.current) {
      updateData();
      updateData.flush();
      isMount.current = false;
    } else {
      updateData();
    }

    return updateData.cancel;
  }, [pagination]);

  const employeePagination = useMemo(() => {
    if (data) {
      const {total, totalPages} = data.meta.pagination;

      return {
        ...pagination,
        total,
        totalPages,
      };
    }

    return pagination;
  }, [pagination, data]);

  return {
    list: data?.data ?? [],
    updatePagination,
    pagination: employeePagination,
    isLoading,
    updateData,
  };
};

export default usePaginatedEmployees;
