import React, {FC, memo} from 'react';
import {Datepicker, DatepickerProps} from '../Datepicker';
import {
  Control,
  Controller,
  FieldErrors,
  ControllerRenderProps,
} from 'react-hook-form';

type FormDatepickerProps = Omit<DatepickerProps, 'onChange' | 'value'> & {
  name: string;
  control: Control;
  errors?: FieldErrors;
};

export const FormDatepicker: FC<FormDatepickerProps> = memo(
  ({name, control, errors, ...restProps}) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({value, onChange}: ControllerRenderProps) => (
          <Datepicker
            {...restProps}
            error={errors ? errors?.[name]?.message : ''}
            onChange={onChange}
            value={value}
          />
        )}
      />
    );
  },
);
