import React, {FC, useCallback, useState} from 'react';
import PageToolbar from '../../components/PageToolbar/PageToolbar';
import {Tab} from '@material-ui/core';
import DefaultTabContent from '../../components/Tabs/Tabs';
import {TabsHeader} from '../../components/Tabs/TabsHeader';
import AbsencesForHRTableToApprove from './components/table/AbsencesForHRTableToApprove';
import AbsencesForHRTableHistory from './components/table/AbsencesForHRTableHistory';

const AbsencesForHRPage: FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setTabIndex(newValue);
    },
    [],
  );

  const a11yProps = (index: any) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <div className="container">
      <PageToolbar title="Absences" icon="icon-nav-item-4" />
      <div className="table-tab-header">
        <TabsHeader value={tabIndex} onChange={handleChangeTab}>
          <Tab label="My history" {...a11yProps(0)} />
          <Tab label="To approve" {...a11yProps(1)} />
          <Tab label="History of all employees" {...a11yProps(2)} />
        </TabsHeader>
      </div>

      <DefaultTabContent value={tabIndex} index={0} paddingFree>
        <AbsencesForHRTableToApprove />
      </DefaultTabContent>
      <DefaultTabContent value={tabIndex} index={1} paddingFree>
        1
      </DefaultTabContent>
      <DefaultTabContent value={tabIndex} index={2} paddingFree>
        <AbsencesForHRTableHistory />
      </DefaultTabContent>
    </div>
  );
};

export default AbsencesForHRPage;
