import {
  createStore,
  applyMiddleware,
  Middleware,
  StoreEnhancer,
  Store,
} from 'redux';

import rootReducer, {StoreState} from './rootReducer';

const bindMiddleware = (middleware: Array<Middleware>): StoreEnhancer => {
  if (process.env.NODE_ENV !== 'production') {
    const {composeWithDevTools} = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

let store: Store<StoreState>;

export function configureStore(initialState = {}): Store<StoreState> {
  store = createStore(rootReducer, initialState, bindMiddleware([]));
  return store;
}

export function getStore(): Store<StoreState> {
  if (!store) {
    return configureStore();
  }

  return store;
}
