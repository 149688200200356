import React, {ChangeEventHandler, FC, useCallback, useState} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button, InputLabel, TextField} from '@material-ui/core';
import {DepartmentI} from 'interfaces/department';
import {editDepartment} from 'services/deparments';
import {APIError, StatusCodes} from 'http-client/types';

type EditDepartmentProps = {
  open: boolean;
  department: DepartmentI;
  fetchDepartments: () => void;
};

export const EditDepartment: FC<EditDepartmentProps> = ({
  open,
  department,
  fetchDepartments,
}): JSX.Element => {
  const {closeModal} = useModals();

  const [name, setName] = useState<string>(department.name);
  const [error, setError] = useState<string>('');

  const onClose = useCallback(() => closeModal(MODALS.EditDepartment), [
    closeModal,
  ]);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    setName(event.target.value);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      await editDepartment({
        id: department.id,
        name,
      });

      onClose();
      fetchDepartments();
    } catch (_error: unknown) {
      const error = _error as APIError<DepartmentI>;

      if (
        error.response.status === StatusCodes.BadRequest ||
        error.response.status === StatusCodes.Unauthorized
      ) {
        setError(error.response.data.error);
        return;
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">Edit department</h3>
      </div>
      <div className="modal-content">
        <div className="form-control input-row">
          <InputLabel htmlFor="department">Department name</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            id="department"
            name="department"
            value={name}
            onChange={handleChangeName}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button disableRipple onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={handleSubmit}>
          Edit department
        </Button>
      </div>
    </Modal>
  );
};
