import React, {FC, useCallback} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button} from '@material-ui/core';
import {EmployeeInfoCard} from '../../components/EmployeeCard/EmployeeInfoCard';
import {EmployeeInfo} from '../../components/EmployeeCard/EmployeeInfo';
import {useTranslation} from 'react-i18next';
import {AbsenceI} from '../../interfaces/absence';

type CurrentAbsencesProps = {
  open: boolean;
  props: {
    modalData: AbsenceI[];
  };
};

export const CurrentAbsences: FC<CurrentAbsencesProps> = ({open, props}) => {
  const {t} = useTranslation(['absencesSection']);
  const {closeModal} = useModals();

  const onRequestClose = useCallback(() => closeModal(MODALS.CurrentAbsences), [
    closeModal,
  ]);

  return (
    <Modal contentScrolled open={open} onClose={onRequestClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          {t('absencesSection:tabs.currentAbsences')}
        </h3>
      </div>
      <div className="modal-content">
        {props.modalData.map((absence) => {
          return (
            <EmployeeInfoCard
              key={absence.id}
              avatarSrc={absence.employee.avatar}
              name={absence.employee.name.name}
              role={absence.employee.position.name}>
              <EmployeeInfo
                classes="employee-info-card__data--info"
                iconClass={absence.type?.type}
                text={absence.type?.name}
                from={absence.dateFrom}
                to={absence.dateTo}
              />
            </EmployeeInfoCard>
          );
        })}
      </div>
      <div className="modal-footer">
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={onRequestClose}>
          {t('absencesSection:close')}
        </Button>
      </div>
    </Modal>
  );
};
