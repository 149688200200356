import React, {FC} from 'react';
import {Tab, Tabs} from '@material-ui/core';

type TabsHeaderProps = {
  value: any;
  onChange: any;
  vertical?: boolean;
};

export const TabsHeader: FC<TabsHeaderProps> = ({
  value,
  onChange,
  vertical,
  children,
}) => {
  return (
    <Tabs
      value={value}
      orientation={vertical ? 'vertical' : 'horizontal'}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example">
      {children}
    </Tabs>
  );
};
