import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  initialState as defaultProfile,
  UserProfileContext,
} from './UserProfileContext';
import {APIProfile} from '../../services/profile/types';
import {getProfile} from '../../services/profile';
import useLazyQuery from '../../hooks/useLazyQuery';
import {AuthContext} from '../Auth';
import SplashScreen from '../../components/SplashScreen/SplashScreen';

interface IUserProfileProps {
  children: JSX.Element | JSX.Element[];
}

export const UserProfileProvider: FC<IUserProfileProps> = ({children}) => {
  const [getProfileInfo, {isLoading}] = useLazyQuery<
    APIProfile['response']['data']
  >(getProfile);

  const [profile, setProfile] = useState(defaultProfile);
  const {authorized} = useContext(AuthContext);

  useEffect(() => {
    if (authorized) {
      updateProfile();
    }
  }, [authorized]);

  const updateProfile = useCallback(() => {
    getProfileInfo().then((data) => {
      if (data) {
        setProfile(data);
      }
    });
  }, [getProfileInfo]);

  const userContext = useMemo(() => ({profile, updateProfile}), [
    profile,
    updateProfile,
  ]);

  return (
    <UserProfileContext.Provider value={userContext}>
      {isLoading ? <SplashScreen isFullScreen={false} /> : children}
    </UserProfileContext.Provider>
  );
};
