import {PaginationWithTotal} from './types';

export const DEFAULT_PAGE_SIZE = 20;

export const initialPaginationParams: PaginationWithTotal = {
  page: 1,
  total: 1,
  perPage: 10,
  totalPages: 1,
  sort: '',
  order: 'ASC',
  search: '',
};
