import React, {FC} from 'react';
import {IEmployeeColumnProps} from '../../../type';
import dayjs from 'dayjs';

const BirthdayColumn: FC<IEmployeeColumnProps> = ({employee: {birthday}}) => {
  return (
    <div className="employee-data">
      <p>{dayjs(birthday * 1000).format('DD MMMM')}</p>
    </div>
  );
};

export default BirthdayColumn;
