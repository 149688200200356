import atob from 'atob';
import btoa from 'btoa';
import Cookies from 'js-cookie';
import {CookieDataI} from './types';
import {CookiesName} from './cookiesName';

export class CredentialsManager {
  public static storeKey = 'TSID'; //@todo should be replaced with env

  public static getCredentials(): CookieDataI | undefined {
    const storedKey =
      Cookies.get(CredentialsManager.storeKey) ||
      sessionStorage.getItem(CredentialsManager.storeKey);

    if (!storedKey) {
      return undefined;
    }
    return CredentialsManager.parseCredentialsData<CookieDataI>(storedKey);
  }

  public static setCredentials(data: CookieDataI, hasLongTerms: boolean): void {
    const key = btoa(JSON.stringify({...data, generated_in: Date.now()}));

    if (hasLongTerms) {
      Cookies.set(CredentialsManager.storeKey, key, {
        expires: 2147483647,
        path: '/',
      });
    } else {
      sessionStorage.setItem(CredentialsManager.storeKey, key);
    }
  }

  //@todo need to add listener for localstorage
  public static removeCredentials(): void {
    // to support logging out from all windows
    window.localStorage.setItem('logout', Date.now().toString());
    Cookies.remove(CredentialsManager.storeKey);
    sessionStorage.removeItem(CredentialsManager.storeKey);
  }

  private static parseCredentialsData<T>(cookieData: string): T | undefined {
    try {
      return JSON.parse(atob(cookieData));
    } catch (error) {
      return undefined;
    }
  }

  public static cookieData<T>(cookieName: CookiesName): T | undefined {
    const cookieData = Cookies.get(
      `${CredentialsManager.storeKey}:${cookieName}`,
    );

    if (!cookieData) {
      return undefined;
    }

    return CredentialsManager.parseCredentialsData<T>(cookieData);
  }

  public static saveCookieData<T>(cookieName: CookiesName, payload: T): void {
    Cookies.set(
      `${CredentialsManager.storeKey}:${cookieName}`,
      btoa(JSON.stringify({...payload, generated_in: Date.now()})),
      {
        expires: 2147483647,
        path: '/',
      },
    );
  }
}
