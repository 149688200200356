import {useState, useEffect} from 'react';

interface IUseAPIOptions {
  options: Array<any>;
  loading: boolean;
}

const useAPIOptions = (getOption: () => Promise<any>): IUseAPIOptions => {
  const [options, setOption] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getOptions = async () => {
    setLoading(true);
    try {
      const result = await getOption();
      const optionFromAPI: any = [];
      result.data.map((item: any) => {
        optionFromAPI.push({
          value: item.id,
          label: item.name,
        });
      });
      setOption(optionFromAPI);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect((): void => {
    getOptions();
  }, []);
  return {
    options,
    loading,
  };
};

export default useAPIOptions;
