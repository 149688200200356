import React, {FC, useCallback, useMemo, useState} from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

export enum LoginStep {
  login,
  phone,
  smsCode,
}

const Login: FC = (): JSX.Element => {
  const [step, setStep] = useState<LoginStep>(LoginStep.login);
  const [hasRememberUser, setHasRememberUser] = useState<boolean>(false);

  const [digits, setDigits] = useState<string>('');

  const goToStep2 = (): void => {
    setStep(LoginStep.phone);
  };

  const goToStep3 = (newDigits: string): void => {
    setDigits(newDigits);
    setStep(LoginStep.smsCode);
  };

  const handleChangeHasRememberUser = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasRememberUser(event.target.checked);
    },
    [],
  );

  const currentStep = useMemo(() => {
    switch (step) {
      case LoginStep.login:
        return (
          <Step1
            nextStep={goToStep2}
            hasRememberUser={hasRememberUser}
            handleChangeHasRememberUser={handleChangeHasRememberUser}
          />
        );

      case LoginStep.phone:
        return <Step2 nextStep={goToStep3} />;

      case LoginStep.smsCode:
        return <Step3 digits={digits} hasRememberUser={hasRememberUser} />;
    }
  }, [step, hasRememberUser, handleChangeHasRememberUser, digits]);

  return (
    <div id="wrapper">
      <div className="full-height-holder">
        <div className="full-height-inner">{currentStep}</div>
      </div>
    </div>
  );
};

export default Login;
