import React, {FC, useRef, useState} from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import classNames from 'classnames';

interface MenuItemI {
  title: string;
  icon?: string;
  red?: boolean;
  onClick: () => void;
}

type DropdownMenuProps = {
  fullwidth?: boolean;
  iconStart?: string;
  sm?: boolean;
  isIconButton?: boolean;
  menuItems?: Array<MenuItemI>;
  title?: string;
  perPageSelect?: boolean;
};

export const DropdownMenu: FC<DropdownMenuProps> = ({
  fullwidth,
  iconStart,
  sm,
  isIconButton,
  menuItems = [],
  title,
  perPageSelect,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {!!isIconButton ? (
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'dropdown-menu' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          disableRipple>
          <i className="icon-dots" />
        </IconButton>
      ) : (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'dropdown-menu' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classNames('button-select', {
            sm: sm,
            'per-page-select': perPageSelect,
          })}
          fullWidth={fullwidth}
          startIcon={!!iconStart && <i className={iconStart} />}
          endIcon={<i className="icon-arrow-drop-down" />}
          disableRipple>
          <span className="placeholder">
            {!!title ? title : 'Choose something'}
          </span>
        </Button>
      )}
      <Popper
        className="dropdown-menu"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        disablePortal>
        {({TransitionProps, placement}) => (
          <Grow {...TransitionProps}>
            <Paper className={classNames('dropdown-holder', {sm: sm})}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="dropdown-menu"
                  onKeyDown={handleListKeyDown}
                  className="dropdown-list">
                  {menuItems.map((menuItem, index) => {
                    // using array instead of Fragment because of Material UI warning/error
                    const itemsToRender = [
                      <MenuItem
                        key={menuItem.title}
                        onClick={(event) => {
                          handleClose(event);
                          menuItem.onClick();
                        }}>
                        <div
                          className={`menu-item ${menuItem.red ? 'red' : ''}`}>
                          <span>{menuItem.title}</span>
                          {!!menuItem.icon && <i className={menuItem.icon} />}
                        </div>
                      </MenuItem>,
                    ];

                    if (index < menuItems.length - 1) {
                      itemsToRender.push(<hr className="menu-divider" />);
                    }

                    return itemsToRender;
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
