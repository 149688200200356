import React, {FC, memo} from 'react';
import {DropdownMenu} from 'components/DropdownMenu/DropdownMenu';
import useLazyQuery from 'hooks/useLazyQuery';
import {deleteEmployee as requestDeleteEmployee} from 'services/employee';
import {MODALS, useModals} from 'containers';
import {Employee} from 'interfaces/employee';
import {UpdateData, IEmployeeColumnProps} from '../../../type';

interface IActionsColumnProps extends IEmployeeColumnProps {
  updateData: UpdateData;
  employee: Employee;
}

const ActionsColumn: FC<IActionsColumnProps> = ({employee, updateData}) => {
  const [deleteEmployee] = useLazyQuery(requestDeleteEmployee);
  const {openModal} = useModals();

  const handleEditEmployee = () => {
    openModal(MODALS.Employee, {employee});
  };

  const handleDeleteEmployee = () => {
    deleteEmployee({id: employee.id}).then(() => {
      updateData();
      updateData.flush();
    });
  };

  const menuItems = [
    {
      title: 'Edit employee',
      onClick: handleEditEmployee,
    },
    {
      title: 'Delete',
      onClick: handleDeleteEmployee,
    },
  ];

  return (
    <div className="button-right">
      <DropdownMenu isIconButton sm menuItems={menuItems} />
    </div>
  );
};

export default memo(ActionsColumn);
