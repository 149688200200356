import {useState, useEffect} from 'react';

interface IUseQueryParams<RequestType, ResponseType> {
  request: (params: RequestType) => Promise<any>;
  params?: RequestType;
}

type IUseQueryResult<ResponseType> = {
  data: ResponseType | undefined;
  isError: boolean;
  isLoading: boolean;
  error: Record<string, unknown> | string | null;
};

const useQuery = <ResponseType, RequestType = undefined>(
  request: IUseQueryParams<RequestType, ResponseType>['request'],
  params: IUseQueryParams<
    RequestType,
    ResponseType
  >['params'] = {} as RequestType,
): IUseQueryResult<ResponseType> => {
  const [data, setData] = useState<ResponseType | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Record<string, unknown> | string | null>(
    null,
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await request(params);

        setData(result as ResponseType);
      } catch (error) {
        setError(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return {data, isError, isLoading, error};
};

export default useQuery;
