import React, {FC, useCallback} from 'react';
import {MODALS, useModals} from 'containers/Modal';
import {Modal} from '../Modal';
import {Button} from '@material-ui/core';
import {EmployeeInfoCard} from '../../components/EmployeeCard/EmployeeInfoCard';
import {EmployeeInfo} from '../../components/EmployeeCard/EmployeeInfo';
import {useTranslation} from 'react-i18next';
import {VocationI} from '../../interfaces/absence';

type ClosestVacationsProps = {
  open: boolean;
  props: {
    modalData: VocationI[];
  };
};

export const ClosestVacations: FC<ClosestVacationsProps> = ({open, props}) => {
  const {t} = useTranslation(['absencesSection']);
  const {closeModal} = useModals();

  const onRequestClose = useCallback(
    () => closeModal(MODALS.ClosestVacations),
    [closeModal],
  );

  return (
    <Modal contentScrolled open={open} onClose={onRequestClose}>
      <div className="modal-header">
        <h3 className="modal-header__title">
          {t('absencesSection:tabs.closestVacations')}
        </h3>
      </div>
      <div className="modal-content">
        {props.modalData.map((vocation) => {
          return (
            <EmployeeInfoCard
              key={vocation.id}
              avatarSrc={vocation.employee.avatar}
              name={vocation.employee.name.name}
              role={vocation.employee.position.name}>
              <EmployeeInfo
                classes="employee-info-card__data--info"
                iconClass={vocation.type?.type}
                text={vocation.type?.name}
                from={vocation.dateFrom}
                to={vocation.dateTo}
              />
            </EmployeeInfoCard>
          );
        })}
      </div>
      <div className="modal-footer">
        <Button
          variant="contained"
          color="primary"
          disableRipple
          onClick={onRequestClose}>
          {t('absencesSection:close')}
        </Button>
      </div>
    </Modal>
  );
};
