import React, {FC, memo} from 'react';
import {IEmployeeColumnProps} from '../../../type';
import {BalanceType} from '../../../../../constants';

const BalanceColumn: FC<IEmployeeColumnProps> = ({
  employee: {currentAbsence},
}) => {
  if (!currentAbsence?.type) {
    return null;
  }

  return (
    <div className="employee-data">
      <p className="text-with-icon">
        {/*// @ts-ignore*/}
        <i className={BalanceType[currentAbsence?.type]?.icon} />
        {/*// @ts-ignore*/}
        {BalanceType[currentAbsence?.type]?.title}
      </p>
    </div>
  );
};

export default memo(BalanceColumn);
